<template>
<v-app id="inspire" style="background:#F9F9F9;">
<vue-header-admin></vue-header-admin>
<v-main>

    <v-container fluid style="margin-bottom: 72px;"  class="px-6 py-6">
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mb-5" id="basic">
                <v-card-text >
                  <v-row>
                        
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Plan 1</h5>
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Name"
                        class="font-size-input input-style"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Description"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan1.description"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        label="Monthly Price"
                        color="green"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan1.monthly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Yearly Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.yearly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Setup Fee"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.setup_fee"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.team_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.ad_ons_client_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.ad_ons_team_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.ad_ons_client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan1.ad_ons_team_count"
                        />

                    </v-col>
                    
                   <v-col cols="12">
                     <v-data-table
                          :headers="headers_basic"
                          :items="settings.plan1.items"
                          sort-by="calories"
                          class="table mb-10"
                        >
                          <template v-slot:top>
                            <v-toolbar
                              flat
                            >
                              <v-toolbar-title>Items</v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                              <v-btn
                                    color="blue"
                                    dark
                                    @click="dialog_basic = true,selected_item = 'plan1'"
                                    class="mb-2"
                                  >
                                    New Item
                                  </v-btn>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="material-icons-round mr-2"
                              @click="editItemBasic('plan1',item)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              class="material-icons-round"
                              @click="deleteItemBasic('plan1',item)"
                            >
                              delete
                            </v-icon>
                          </template>
                        </v-data-table>
                   </v-col>
                    </v-row>
                    </v-card-text>
                    </v-card>
                    <v-card class="card-shadow border-radius-xl mb-5" id="basic">
                <v-card-text >
                  <v-row>
                        
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Plan 2</h5>
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Name"
                        class="font-size-input input-style"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Description"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan2.description"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        label="Monthly Price"
                        color="green"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan2.monthly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Yearly Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.yearly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Setup Fee"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.setup_fee"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.team_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.ad_ons_client_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.ad_ons_team_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.ad_ons_client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan2.ad_ons_team_count"
                        />

                    </v-col>
                    
                   <v-col cols="12">
                     <v-data-table
                          :headers="headers_basic"
                          :items="settings.plan2.items"
                          sort-by="calories"
                          class="table mb-10"
                        >
                          <template v-slot:top>
                            <v-toolbar
                              flat
                            >
                              <v-toolbar-title>Items</v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                               <v-btn
                                    color="blue"
                                    dark
                                    @click="dialog_basic = true,selected_item = 'plan2'"
                                    class="mb-2"
                                  >
                                    New Item
                                  </v-btn>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="material-icons-round mr-2"
                              @click="editItemBasic('plan2',item)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              class="material-icons-round"
                              @click="deleteItemBasic('plan2',item)"
                            >
                              delete
                            </v-icon>
                          </template>
                        </v-data-table>
                   </v-col>
                    </v-row>
                    </v-card-text>
                    </v-card>
                    <v-card class="card-shadow border-radius-xl mb-5" id="basic">
                <v-card-text >
                  <v-row>
                        
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Plan 3</h5>
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Name"
                        class="font-size-input input-style"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Description"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan3.description"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        label="Monthly Price"
                        color="green"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan3.monthly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Yearly Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.yearly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Setup Fee"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.setup_fee"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.team_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.ad_ons_client_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.ad_ons_team_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.ad_ons_client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan3.ad_ons_team_count"
                        />

                    </v-col>
                    
                   <v-col cols="12">
                     <v-data-table
                          :headers="headers_basic"
                          :items="settings.plan3.items"
                          sort-by="calories"
                          class="table mb-10"
                        >
                          <template v-slot:top>
                            <v-toolbar
                              flat
                            >
                              <v-toolbar-title>Items</v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                               <v-btn
                                    color="blue"
                                    dark
                                    @click="dialog_basic = true,selected_item = 'plan3'"
                                    class="mb-2"
                                  >
                                    New Item
                                  </v-btn>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="material-icons-round mr-2"
                              @click="editItemBasic('plan3',item)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              class="material-icons-round"
                              @click="deleteItemBasic('plan3',item)"
                            >
                              delete
                            </v-icon>
                          </template>
                        </v-data-table>
                   </v-col>
                    </v-row>
                    </v-card-text>
                    </v-card>
                    <v-card class="card-shadow border-radius-xl mb-5" id="basic">
                <v-card-text >
                  <v-row>
                        
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Plan 4</h5>
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Name"
                        class="font-size-input input-style"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        label="Description"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan4.description"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        label="Monthly Price"
                        color="green"
                        width="300px"
                        class="font-size-input input-style"
                        ref="name"
                        v-model="settings.plan4.monthly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Yearly Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.yearly"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Setup Fee"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.setup_fee"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.team_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.ad_ons_client_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Price"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.ad_ons_team_price"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Client Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.ad_ons_client_count"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        color="green"
                        class="font-size-input input-style"
                        label="Ad on Team Count"
                        width="300px"
                        ref="name"
                        v-model="settings.plan4.ad_ons_team_count"
                        />

                    </v-col>
                    
                   <v-col cols="12">
                     <v-data-table
                          :headers="headers_basic"
                          :items="settings.plan4.items"
                          sort-by="calories"
                          class="table mb-10"
                        >
                          <template v-slot:top>
                            <v-toolbar
                              flat
                            >
                              <v-toolbar-title>Items</v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                               <v-btn
                                    color="blue"
                                    dark
                                    @click="dialog_basic = true,selected_item = 'plan4'"
                                    class="mb-2"
                                  >
                                    New Item
                                  </v-btn>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="material-icons-round mr-2"
                              @click="editItemBasic('plan4',item)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              class="material-icons-round"
                              @click="deleteItemBasic('plan4',item)"
                            >
                              delete
                            </v-icon>
                          </template>
                        </v-data-table>
                   </v-col>
                    </v-row>
                    </v-card-text>
                    </v-card>
            <v-card class="card-shadow border-radius-xl mt5" id="basic">
                <v-card-actions class="justify-center ">
                  <v-btn
                    color="#4CAF50"
                    large
                    :loading="loadingSetting"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                                        
                                      "
                    @click="saveSettings"
                  >Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog
                                v-model="dialog_basic"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                        >
                                          <v-text-field
                                            v-model="editedItemBasic.item"
                                            label="Enter Item"
                                          ></v-text-field>
                                        </v-col>
                                        
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeBasic"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="saveBasic(selected_item)"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-dialog v-model="dialogDeleteBasic" max-width="500px">
                                <v-card>
                                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeleteBasic">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmBasic(selected_item)">OK</v-btn>
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
    </v-container>
    </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        phone:'',
        password : '',
        confpassword : '',
        role : '',
        companyname : '',
        sector : '',
        loadingSetting : false,
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        otp_phone_input:"",
        otp_email_input:"",
        dialogDeleteBasic : false,
        selected_item : '',
        dialogDeleteStandard : false,
        dialogDeletePremium : false,
        user_phone:'',
        user_email:'',
        status:0,
        dialog_basic: false,
        dialog_standard: false,
        dialog_premium: false,
        dialogDelete: false,
        headers_basic: [
          { text: 'Item', value: 'item' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        headers_standard: [
          { text: 'Item', value: 'item' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        headers_premium: [
          { text: 'Item', value: 'item' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItemBasic: {
          item: '',
        },
        editedItemStandard: {
          item: '',
        },
        editedItemPremium: {
          item: '',
        },
        defaultItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        settings :{
           plan1: {
             name :'',
             items : [],
             description : "",
             setup_fee : 0,
             monthly : 0,
             yearly : 0,
             team_count : 0,
             client_count : 0,
             ad_ons_team_count :  0,
             ad_ons_client_count : 0,
             ad_ons_team_price :  0,
             ad_ons_client_price : 0
           },
           plan2: {
             name :'',
             items : [],
             description : "",
             setup_fee : 0,
             monthly : 0,
             yearly : 0,
             team_count : 0,
             client_count : 0,
             ad_ons_team_count :  0,
             ad_ons_client_count : 0,
             ad_ons_team_price :  0,
             ad_ons_client_price : 0
           },
           plan3: {
             name :'',
             items : [],
             description : "",
             setup_fee : 0,
             monthly : 0,
             yearly : 0,
             team_count : 0,
             client_count : 0,
             ad_ons_team_count :  0,
             ad_ons_client_count : 0,
             ad_ons_team_price :  0,
             ad_ons_client_price : 0
           },
           plan4: {
             name :'',
             items : [],
             description : "",
             setup_fee : 0,
             monthly : 0,
             yearly : 0,
             team_count : 0,
             client_count : 0,
             ad_ons_team_count :  0,
             ad_ons_client_count : 0,
             ad_ons_team_price :  0,
             ad_ons_client_price : 0
           },

        },
        tab: null,


      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog_basic (val) {
        val || this.closeBasic()
      },
      dialog_standard (val) {
        val || this.closeStandard()
      },
      dialog_premium (val) {
        val || this.closePremium()
      },
      dialogDeleteBasic (val) {
        val || this.closeDeleteBasic()
      },
      dialogDeleteStandard (val) {
        val || this.closeDeleteStandard()
      },
      dialogDeletePremium (val) {
        val || this.closeDeletePremium()
      },
    },
    methods: {
      editItemBasic (flag,item) {
        this.editedIndexBasic = this.settings[flag].items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialog_basic = true
      },
      editItemStandard (item) {
        this.editedIndexStandard = this.settings.standard.items.indexOf(item)
        this.editedItemStandard = Object.assign({}, item)
        this.dialog_standard = true
      },
      editItemPremium (item) {
        this.editedIndexPremium = this.settings.premium.items.indexOf(item)
        this.editedItemPremium = Object.assign({}, item)
        this.dialog_premium = true
      },

      deleteItemBasic (flag,item) {
        this.editedIndexBasic = this.settings[flag].items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialogDeleteBasic = true
      },
      deleteItemStandard (item) {
        this.editedIndexStandard = this.settings.standard.items.indexOf(item)
        this.editedItemStandard = Object.assign({}, item)
        this.dialogDeleteStandard = true
      },
      deleteItemPremium (item) {
        this.editedIndexPremium = this.settings.premium.items.indexOf(item)
        this.editedItemPremium = Object.assign({}, item)
        this.dialogDeletePremium = true
      },

      deleteItemConfirmBasic (plan) {
        this.settings[plan].items.splice(this.editedIndexBasic, 1)
        this.closeDeleteBasic()
      },
      deleteItemConfirmStandard () {
        this.settings.standard.items.splice(this.editedIndexStandard, 1)
        this.closeDeleteStandard()
      },
      deleteItemConfirmPremium () {
        this.settings.premium.items.splice(this.editedIndexPremium, 1)
        this.closeDeletePremium()
      },

      closeBasic () {
        this.dialog_basic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item : ''})
          this.editedIndexBasic = -1
        })
      },
      closeStandard () {
        this.dialog_standard = false
        this.$nextTick(() => {
          this.editedItemStandard = Object.assign({}, {item : ''})
          this.editedIndexStandard = -1
        })
      },
      closePremium () {
        this.dialog_premium = false
        this.$nextTick(() => {
          this.editedItemPremium = Object.assign({}, {item : ''})
          this.editedIndexPremium = -1
        })
      },
      closeDeleteBasic () {
        this.dialogDeleteBasic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item:''})
          this.editedIndexBasic = -1
        })
      },
      closeDeleteStandard () {
        this.dialogDeleteStandard = false
        this.$nextTick(() => {
          this.editedItemStandard = Object.assign({}, {item:''})
          this.editedIndexStandard = -1
        })
      },
      closeDeletePremium () {
        this.dialogDeletePremium = false
        this.$nextTick(() => {
          this.editedItemPremium = Object.assign({}, {item:''})
          this.editedIndexPremium = -1
        })
      },

      saveBasic (item) {
        console.log(this.settings);
        if (this.editedIndexBasic > -1) {
          Object.assign(this.settings[item].items[this.editedIndexBasic], this.editedItemBasic)
        } else {
          this.settings[item].items.push(this.editedItemBasic)
        }
        this.closeBasic()
      },
      saveStandard () {
        if (this.editedIndexStandard > -1) {
          Object.assign(this.settings.standard.items[this.editedIndexStandard], this.editedItemStandard)
        } else {
          this.settings.standard.items.push(this.editedItemStandard)
        }
        this.closeStandard()
      },
      savePremium () {
        if (this.editedIndexPremium > -1) {
          Object.assign(this.settings.premium.items[this.editedIndexPremium], this.editedItemPremium)
        } else {
          this.settings.premium.items.push(this.editedItemPremium)
        }
        this.closePremium()
      },
    getSettings() {
    let flag = 1;
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-site-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              if(data.settings)
                this.settings = data.settings;
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveSettings: function(e) {
      this.loadingSetting = true;
        axios({
          method: "POST",
          url: "/api/update-site-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            this.loadingSetting = false;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },

 },

created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getSettings();
    }
      
    },

}
</script>

