<template>
    <v-app >

    <v-main class="auth-pages">
     <div
        class="position-relative min-vh-100"
        :style="company && loadingLogo &&  company.logo ? `background-image: url(${base_url_img+company.logo_background}); background-size: cover;` : `background-image: url(${backgroundLogo}); background-size: cover;` "
      >
        <span class="mask bg-gradient-default opacity-8"></span>
              <v-container class="pb-0 position-relative" style="justify-content: space-around;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);">
                <div style="display: flex; justify-content: space-around" class="mb-1">
    </div>
                <v-row class="align-center d-flex h-100 " style="" align="center" justify="center">
                      <v-col lg="5" md="7" class="mx-auto">
                        <v-card class="card-shadow border-radius-xl py-1" style="background-color:transparent;">
                         
                          <div class="card-padding pb-0" style="text-align: center;">
                          <img v-if="!company && loadingLogo" :height="this.$vuetify.breakpoint.xs ? 100 : 100" class="image-logo2" src="@/assets/img/logo-mst.png">
      <img v-if="company && loadingLogo &&  company.logo" :height="this.$vuetify.breakpoint.xs ? 150 : 200" class="image-logo2" :src="base_url_img+company.logo">
                          <h2 class="text-h2 font-weight-bolder  mb-2 mt-5 text-white">Welcome Admin!</h2>
                          </div>
                          <div class="card-padding" v-if="pageFlag=='login'">
                           
                            <v-text-field
                              label="Email"
                              placeholder="Email"
                              color="#FDE038"
                              v-model="email"
                              required
                              rounded
                              dense
                              outlined
                              class="font-size-input input-style py-0 input-login"
                            ></v-text-field>

                            <v-text-field
                              label="Password"
                              v-model="password"
                              id="pass"
                              placeholder="Password"
                              color="#FDE038"
                              required
                              rounded
                              outlined
                              dense
                              type="password"
                              class="font-size-input input-style input-login"
                            ></v-text-field>


                            <v-btn
                              elevation="0"
                              :ripple="false"
                              height="43"
                              rounded
                              outlined
                              v-on:click="checkForm2"
                              class="
                                font-weight-bold
                                text-uppercase
                                btn-default
                                py-2
                                px-6
                                me-2
                                mt-6
                                mb-2
                                w-100
                              "
                              color="#FDE038"
                              small
                              >Sign In</v-btn
                            >
                            <p class="text-sm text-body mt-3 mb-0">
                              Don't remember your password?
                              <a
                              v-on:click="pageFlag='forgot'"
                                href="#"
                                class="text-white text-decoration-none font-weight-bolder"
                                >Forgot password</a
                              >
                            </p>
                          </div>
                          <div class="card-padding" v-else>
                          
                           <div style="display: flex; justify-content: center; margin-top:0px;" >
                                 <v-btn
                                  fab
                                  dark
                                  x-small
                                  class="mr-5"
                                  @click="pageFlag='login'"
                                  
                            color="black"
                          >
                            <v-icon>fa-arrow-left</v-icon>
                          </v-btn>
                              <h5 class="text-h4 font-weight-bold text-white">Reset password</h5>
                          </div>
                            <v-text-field
                              label="Email"
                              placeholder="Email"
                              color="#e91e63"
                              v-model="email"
                              required
                              outlined
                              rounded
                              class="font-size-input input-style mt-5 input-login"
                            ></v-text-field>

                            
                            <v-btn
                              elevation="0"
                              :ripple="false"
                              height="43"
                              :loading="buttonFlag"
                              v-on:click="forgotPass" 
                              class="
                                font-weight-bold
                                text-uppercase
                                btn-default
                                py-2
                                px-6
                                me-2
                                mt-6
                                mb-2
                                w-100
                              "
                              color="#4AA64E"
                              small
                              >Continue</v-btn
                            >
                           
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
              </v-container>
            </div>
            </v-main>






    <!-- <div style="display: flex; justify-content: space-around" class="mt-6 mb-1">
    </div>

    <v-row align="center" justify="center">


        <v-col sm="8" md="3">

        <v-card class="elevation-4 box-login" v-if="pageFlag=='login'">
             <div style="display: flex; justify-content: space-around; margin-top:0px;" >
                <h1 class="" style="margin-top:45px!important;font-family: Lato,Arial,sans-serif;font-weight: 700;line-height: 1;color: #303030!important;">ADMIN LOGIN</h1>
            </div>
            <v-card-text class="mb-0">
            <v-form  class="mt-8">
                <v-subheader style="color: #6b6b6b;font-size: 16px;padding-left: 17px;padding-bottom: 3px;font-weight: 410;">Email</v-subheader>
                <v-text-field
                    outlined
                    style="margin-bottom:-18px;"
                    ref="email"
                    placeholder="Enter Email"
                    v-model="email"
                ></v-text-field>
                <v-subheader style="color: #6b6b6b;font-size: 16px;padding-left: 17px;padding-bottom: 3px;font-weight: 410;">Password</v-subheader>
                <v-text-field
                id="password"
                name="password"
                outlined
                v-model="password"
                placeholder="Enter Password"
                ref="password"
                :type="showPassword ? 'text' : 'password'"
                />
                <div class="field-loginform-rememberme"><a href="#" class="pull-right" style="cursor:pointer;float: right !important;margin-bottom:20px;color: #14B4F0;text-decoration: none;" v-on:click="pageFlag='forgot'">Forgot password?</a></div>
                <v-btn  class="mb-10 mt-5" style="color:white;text-transform: none;font-size:20px;" width="300px" height="58px" color="#14B4F0" v-on:click="checkForm2" block>Log in</v-btn>
            </v-form>
            </v-card-text>

        </v-card>
        <v-card class="elevation-4 box-login" v-else>
            <div style="" >
                <v-btn
                    fab
                    dark
                    class="ml-5 mt-5"
                    @click="pageFlag='login'"
                    small
              color="#14B4F0"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            </div>
             <div style="display: flex; justify-content: center; margin-top:0px;" >
                <h1 class="" style="margin-top:45px!important;font-family: Lato,Arial,sans-serif;font-weight: 700;line-height: 1;color: #303030!important;">Reset password</h1>
            </div>
            <v-card-text class="mb-0">
            <v-form  class="mt-8">
                <v-subheader style="color: #6b6b6b;font-size: 16px;padding-left: 17px;padding-bottom: 3px;font-weight: 410;">Email</v-subheader>
                <v-text-field
                    outlined
                    style="margin-bottom:-18px;"
                    ref="email"
                    placeholder="Enter Email"
                    v-model="email"
                ></v-text-field>
                <v-btn  class="mb-10 mt-5" style="color:white;text-transform: none;font-size:20px;" :disabled="buttonFlag" width="300px" height="58px" color="#14B4F0" v-on:click="forgotPass" block>Continue</v-btn>
            </v-form>
            </v-card-text>

        </v-card>
        </v-col>
    </v-row> -->
</v-app>
</template>

<script>
// import image from "./assets/profile.png"
import Utility from "@/components/common/functions.js";
export default {
props: {
    source: String,
},

data () {
return {
    email : '',
    pageFlag: 'login',
    password : '',
    showPassword : false,
    dialog: false,
    buttonFlag: false,
    backgroundLogo: localStorage.getItem('admin_bg'),
    company : '',
    base_url_img : process.env.VUE_APP_WASBI_URL,
    loadingLogo : false,

}
},
beforeCreate: function () {
    if (localStorage.getItem('token') && localStorage.getItem('is_admin') == 1) {
        this.$router.push('/admin/dashboard')
    }
    
},
created : function (){
  this.url = this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
    if(this.url)
        this.getCompanyInfo(this.url)
    else
        this.loadingLogo = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},

gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log('sdaa',this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
              localStorage.setItem("company_id", result.company_id);
              this.$router.push("/admin/dashboard");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
    checkForm2: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/check-email-exist",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              localStorage.setItem('logged_in_email',this.email);
              localStorage.setItem('logged_in_password',btoa(this.password));
              this.$router.push("/choose-profile");
            } else if (data.flag == 2)  {
                this.checkForm();
            } else{
              Vue.$toast.error(response.data.message, {
            position: 'top-right'
            });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  
},

}
</script>

<style scoped>
   html {
  overflow: hidden !important;
}

</style>
<style>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0,0,0,.6);
}
.separator::before {
    margin-right: .25em;
}
.separator::after {
    margin-left: .25em;
}
</style>
<style>
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login input{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #pass{
  color: #FDE038!important;
}
.input-login #input-19{
  color: #FDE038!important;
}
</style>