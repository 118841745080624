<template>
  <v-app >
    <vue-header-admin></vue-header-admin>
    <v-main  >
    <v-container fluid style="margin-bottom:150px;" class="px-6 py-6">
      <v-row >
        <v-col cols="12">
        <v-btn
            outlined
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="#4CAF50"
             @click="$router.push('/admin/client-profiles')"
              class="
                font-weight-bolder
                btn-primary
                py-5
                px-6
                ms-2
              "
              
            >
            <v-icon class="material-icons-round me-2">arrow_back</v-icon>  Back to list
            </v-btn>
            <div class="d-flex float-right">
                
                <v-btn
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="#4CAF50"
              class="
                font-weight-bolder
                btn-primary
                py-5
                px-6
                ms-2
              "
              
            >
              Send to outsourcing
            </v-btn>
            </div>
            </v-col>
            <v-col cols="12">
            <v-expansion-panels v-model="panel">
            <v-expansion-panel style="background-color:#D7EDF9;"  v-model="panel">
              <v-expansion-panel-header></v-expansion-panel-header>
              <v-expansion-panel-content key="0">
                <v-row>
            <v-col cols="12" md="6">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                    <v-row><v-col cols="12" md="6">
                       <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="10"
                      :value=" client.progress ? (100 * client.progress) / 8 : 0"
                      color="#FFD221"
                      :style="this.$vuetify.breakpoint.xs  ? 'height: 118px;width: 100px;margin: 0 0 0 0;' : 'height: 118px;width: 100px;margin: 0 0 0 0;'"
                    >
                    <h1>{{client.progress? Math.round((100 * client.progress) / 8) : 0}}% </h1>
                    </v-progress-circular><span class="mb-1 text-h3 text-typo font-weight-bold ml-5">
                        Resolved
                        </span>
                        </v-col>
                        <v-col  cols="12" md="6">
                        <v-btn
                        :ripple="false"
                        :href="'tel:'+client.phone_m"
                        elevation="0"
                        large
                      color="#2785cd"
                        class="
                          font-weight-bolder
                          btn-primary
                        "
                        
                      >
                      <v-icon class="material-icons-round me-2">phone</v-icon>
                        CALL: {{client.phone_m}}
                      </v-btn>
                      <div>
                        <v-avatar width="45" height="45" class="shadow rounded-circle mt-2 mr-2" @click="prodigyPost()" style="cursor:pointer;">
                             
                               <img
                                src="@/assets/img/prodology.png"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </v-avatar>
                            <v-avatar width="45" height="45" class="shadow rounded-circle mt-2" @click="prodigyPost()" style="cursor:pointer;">
                             
                             <img
                              src="@/assets/img/panda.png"
                              alt="Avatar"
                              class="rounded-circle"
                            />
                          </v-avatar>
                      </div>
                        </v-col>
                        </v-row>
                        <div class="h-100">
                      <p class="mt-5 mb-1"> <span class="mb-1 text-h4 text-typo font-weight-bold">
                          {{client.first_name ? client.first_name+' '+client.last_name : ''}}
                        </span>
                      <!-- <span> ( Current status : <span class="text-h4" style="color:#1A73E8;">Active</span> )</span> -->
                        </p>

                      </div>
                      <v-row>
                        <v-col cols="6">
                          <div class="customer-side-panel-ssn-left">Cell: {{client.phone_m}}</div><br/>
                          <div class="customer-side-panel-ssn-left">Email: {{client.email}}</div><br/>
                          <div class="customer-side-panel-ssn-left">{{client.address_1}}</div><br/> 
                          <div class="customer-side-panel-ssn-left">{{client.city}}, {{client.state}} {{client.zip}}</div>
                        </v-col>
                        <v-col cols="6">
                          <div class="customer-side-panel-ssn-left">SSN: {{client.ssn}}</div><br/>
                          <div class="customer-side-panel-ssn-left ">DOB: {{client.dob}}</div><br/>
                          <div class="customer-side-panel-ssn-left ">Started: {{client.created_at}}</div><br/>
                           <div class="customer-side-panel-ssn-left ">Created: {{client.created_at}}</div><br/>
                        </v-col>
                      </v-row>
                      <v-textarea
                                    filled
                                     rows="9"
                                     class="mt-5"
                                     placeholder="message..."
                                  ></v-textarea>
                                  
                                                  <v-btn
                                                    depressed
                                                    style="text-transform: none;"
                                                    color="#FFD221"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      ms-3
                                                    "
                                                    @click="saveMemo"
                                                  >
                                                    SEND
                                                  </v-btn>
                    
              </v-card>
              
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                  <div class="h-100">
                      <p class="mt-5 mb-1"> <span class="mb-1 text-h4 text-typo font-weight-bold">
                          Company & Affiliate
                        </span>
                      
                        </p>

                      </div>
                      <hr
                          style="background-color:#d8e1eb;height: 2px;"
                        class="horizontal mt-3"
                      />
                      <v-row class="mt-3">
                          <v-col cols="auto">
                            <v-avatar width="50" height="50" class="shadow rounded-circle">
                              <img
                              v-if="client.logo"
                                :src="wasabi_url+client.logo"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                               <img
                              v-else
                                src="@/assets/img/Quantum Disputes.png"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="auto" class="my-auto">
                            <div class="h-100">
                              <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                Company Assigned
                              </h5>
                              <p class="mb-0 font-weight-light text-body text-md" v-if="client.name">
                                {{client.name}}
                              </p>
                              <p class="mb-0 font-weight-light text-body text-md" v-else>
                                Quantum Resolution Powered by Quantum
                              </p>
                            </div>
                          </v-col>
                          </v-row>
                          <v-row class="mt-3">
                          <v-col cols="auto">
                            <v-avatar width="50" height="50" class="shadow rounded-circle">
                              <img
                                src="@/assets/img/avatar.png"
                                alt="Avatar"
                                class="rounded-circle"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="auto" class="my-auto">
                            <div class="h-100"> 
                              <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                                Affiliate From
                              </h5>
                              <p class="mb-0 font-weight-light text-body text-md">
                                N/A
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="12" class="my-auto mt-5">
                            <v-expansion-panels v-model="panel3">
                              <v-expansion-panel style="background-color:#D7EDF9;"  v-model="panel3">
                                <v-expansion-panel-header style="background: rgb(39, 39, 39);"><h2 class="text-h7 font-weight-bold text-white">Required Documents</h2></v-expansion-panel-header>
                                <v-expansion-panel-content key="0">
                            <v-simple-table style="margin-bottom:20px; margin-top:20px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#272727;">
                        <!-- <tr>
                          <th class="text-left">
                           
                          </th>

                        </tr> -->
                      </thead>
                        <tbody>
                          <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="doc_id_flag">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-if="!doc_id_flag">
                              close
                            </v-icon>  Photo ID </span> <span v-if="doc_id_flag" class="ml-3" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_id_value)">View</span> <span style="float:right;color:#2785CD;cursor: pointer;" @click="onButtonClick('file_id')">Upload</span> <input
                              ref="file_id"
                              class="d-none"
                              type="file"
                              accept="image/*"
                              @change="onFileChanged"
                            ></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="doc_bill_flag">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-if="!doc_bill_flag">
                              close
                            </v-icon>   Utility Bill</span> <span v-if="doc_bill_flag" class="ml-3" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_bill_value)">View</span><span style="float:right;color:#2785CD;cursor: pointer;"  @click="onButtonClick('file_bill')">Upload</span> <input
                              ref="file_bill"
                              class="d-none"
                              type="file"
                              accept="image/*"
                              @change="onFileChanged"
                            ></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span>   <v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="doc_letter_a_flag">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-if="!doc_letter_a_flag">
                              close
                            </v-icon>  Social Security Card</span> <span class="ml-3" v-if="doc_letter_a_flag" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_letter_a_value)">View</span><span style="float:right;color:#2785CD;cursor: pointer;" @click="onButtonClick('file_letter_a')">Upload</span><input
                              ref="file_letter_a"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChanged"
                            ></td>
                            </tr>
                            <!-- <tr>
                            <td class="text-h7" ><span>  <v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="doc_letter_b_flag">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-if="!doc_letter_b_flag">
                              close
                            </v-icon>  Letter B</span> <span class="ml-3" v-if="doc_letter_b_flag" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_letter_b_value)">View</span> <span style="float:right;color:#2785CD;cursor: pointer;" @click="onButtonClick('file_letter_b')">Upload</span><input
                              ref="file_letter_b"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChanged"
                            > </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span> <v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="doc_letter_c_flag">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-else>
                              close
                            </v-icon>  Letter C</span> <span class="ml-3" v-if="doc_letter_c_flag" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_letter_c_value)">View</span><span style="float:right;color:#2785CD;cursor: pointer;" @click="onButtonClick('file_letter_c')">Upload</span><input
                              ref="file_letter_c"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChanged"
                            ></td>
                            </tr> -->
                            <tr>
                         
                            </tr>
                            <tr>
                           
                          </tr>
                          </tbody>
                          </template>
                          </v-simple-table>
                          </v-expansion-panel-content>
                          </v-expansion-panel>
                          </v-expansion-panels>
                          <v-expansion-panels v-model="panel4">
                          <v-expansion-panel style="background-color:#D7EDF9;margin-top:20px;margin-bottom:20px;"  v-model="panel4">
                            <v-expansion-panel-header style="background: rgb(39, 39, 39);"><h2 class="text-h5 font-weight-bold text-white">Contracts</h2></v-expansion-panel-header>
                            <v-expansion-panel-content key="0">
                          <v-simple-table style="margin-bottom:20px;margin-top:20px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#272727;">
                        <!-- <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold text-white">Contracts</h2>
                          </th>

                        </tr> -->
                      </thead>
                        <tbody>
                          <tr v-for="contract_val in contract">
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;margin-right:15px;" v-if="contract_val.contract_status">
                              check
                            </v-icon><v-icon class="material-icons-round" style="color:red;margin-right:15px;" v-else>
                              close
                            </v-icon>  {{contract_val.title}} </span> 
                            
                            <span v-if="contract_val.contract_status" class="ml-3" style="float:right;color:red;cursor: pointer;" @click="removeContract(contract_val.id)">Remove</span>
                            
                            <span v-if="contract_val.contract_status" class="ml-3" style="float:right;color:green;cursor: pointer;" @click="viewContract(contract_val.id)">View</span> <span style="float:right;color:#2785CD;cursor: pointer;" @click="dialogSignContract = true,selectedContract = contract_val, loadSig()" v-if="!contract_val.contract_status">Sign</span> <input
                              ref="file_id"
                              class="d-none"
                              type="file"
                              accept="image/*"
                              @change="onFileChanged"
                            ></td>
                            </tr>
                            <tr></tr>
                          </tbody>
                          </template>
                          </v-simple-table>
                          </v-expansion-panel-content>
                          </v-expansion-panel>
                          </v-expansion-panels>
                          <v-btn
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="blue"
             @click="clientLogin"
              class="
                font-weight-bolder
                btn-primary
                py-5
                float-right
                px-6
                ms-2
              "
              
            >
              Client Portal Login
            </v-btn>
                          </v-col>
                          </v-row>
                          
              </v-card>
              </v-col>
            </v-row>
              </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12">
            <!-- <v-expansion-panels v-model="panel2">
            <v-expansion-panel style="background-color:#D7EDF9;" v-model="panel2">
              <v-expansion-panel-header></v-expansion-panel-header>
              <v-expansion-panel-content> -->
                <v-row>
        <v-col>
           <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <!-- <v-col md="6" class="my-auto">
              <div class="h-100">
                
              <p class="mt-5 mb-1"> <span class="mb-1 text-h3 text-typo font-weight-bold"> <v-avatar size="110" class="my-3 ms-2 mr-3">
            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
            <img v-else :src="avatar" alt="Brooklyn" />
          </v-avatar>
                  {{client.first_name ? client.first_name+' '+client.last_name : ''}}
                </span>
                </p>

              </div>
            </v-col> -->

            <!-- <v-col> -->
              <!-- <hr
              style="background-color:#d8e1eb;height: 2px;"
            class="horizontal"
          /> -->
            <!-- </v-col> -->
            
            <v-tabs background-color="transparent" v-model="model" color="success" show-arrows touchless>
              <v-tabs-slider height="5px;" color="#4CAF50"></v-tabs-slider>
              <v-tab href="#overview">Personal Details</v-tab>
              <v-tab href="#document">Documents</v-tab>
              <v-tab href="#score">Scores</v-tab>
              <v-tab href="#request">Requests</v-tab>
              <v-tab href="#memo">Memo</v-tab>
              <v-tab href="#credit">Credits</v-tab>
              <v-tab href="#round-letter">Round/Letters</v-tab>
              <v-tab href="#letter">Certified Letters</v-tab>
              <v-tab v-if="client.enable_grant" href="#grants">Grants</v-tab>
              <v-tab href="#trade" v-if="trade_flag">Tradelines</v-tab>
            </v-tabs >
          </v-row>
        </v-card>

        </v-col>
        <v-col cols="12">
          <v-tabs-items v-model="model" style="background-color: transparent;" touchless>
            <v-tab-item
              :value="`overview`"
            >
            <v-row>
               <v-col cols="12" md="12">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                  <v-row>
                    <v-col
              md="12"
              class="my-sm-auto ms-sm-auto me-sm-0 mx-auto"
            >
              <div class="d-flex ">
                
                <v-btn
              :ripple="false"
              elevation="0"
              :x-small=" this.$vuetify.breakpoint.xs ? true : false "
             color="#4CAF50"
             @click="importDialog = true"
              class="
                font-weight-bolder
                btn-primary
                py-5
                px-6
                ms-2
              "
              
            >
              Import Credit Report
            </v-btn>
           
            
              </div>
            </v-col>
                   <v-col cols="12" md="6" class="my-auto">
                    <div class="h-100">
                    <p class="mt-5 mb-1"> <span class="mb-1 text-h5 text-typo font-weight-bold">
                        Current Stage: <span style="color:#4CAF50;">{{currentState}}</span>
                      </span>
                    <!-- <span> ( Current status : <span class="text-h4" style="color:#1A73E8;">Active</span> )</span> -->
                      </p>

                    </div>
                  </v-col>
                   <v-col cols="12" md="3" class="my-auto">
                    <div class="h-100">
                    <p class="mt-5 mb-1"> <span class="mb-1 text-h5 text-typo font-weight-bold">
                        Client Status: <span :style="client.status == 1 ? 'color:#2F80C4;' : 'color:red;'">{{client.status == 1 ? 'Active' : 'Inactive'}}</span>
                      </span>
                    <!-- <span> ( Current status : <span class="text-h4" style="color:#1A73E8;">Active</span> )</span> -->
                      </p>

                    </div>
                  </v-col>
                   <v-col  cols="12" md="3" class="my-auto">
                      <v-switch
                      class="pt-5"
                      color="#2F80C4"
                      v-model="switch1"
                      :label="`Active`"
                    ><template #prepend>
          <v-label>Archived</v-label>
        </template></v-switch>
                  </v-col>
                  <v-col cols="12" md="12" >
                      <HVStateStepper
                      class="mt-5"
                        :states="testStates"
                        current-color="red accent-3"
                        current-icon="fa-bullseye"
                        previous-color="green"
                        previous-icon="fa-check"
                      />
                      </v-col>
                      </v-row>
                </v-card>
                </v-col>

              <v-col cols="12" md="12" v-if="!this.$vuetify.breakpoint.xs">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-row>
                <v-col cols="12" md="8">
                        
                <v-simple-table class="margin-bottom:150px;">
                    <template v-slot:default>
                      <thead style="background:#272727;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold white-text">Equifax</h2>
                          </th>
                          <th class="text-left">
                            <h3 class="text-h5 font-weight-bold white-text"> Experian </h3>
                          </th>
                            <th class="text-left">
                            <h3 class="text-h5 font-weight-bold white-text">Transunion</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                        
                        >
                          <td style="background: white;    padding: 0px;"> <JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionseq" class="columnChart"></JSCharting>

                          </td>
                          <td style="background: white;    padding: 0px;"><JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionsex" class="columnChart"></JSCharting></td>
                          <td style="background: white;    padding: 0px;"><JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionstu" class="columnChart"></JSCharting></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>


                  <v-simple-table style="margin-bottom:50px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#EAF1F5;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold ">Status</h2>
                          </th>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold text-black">Equifax</h2>
                          </th>
                          <th class="text-left">
                            <h3 class="text-h5 font-weight-bold text-black"> Experian </h3>
                          </th>
                            <th class="text-left">
                            <h3 class="text-h5 font-weight-bold text-black">Transunion</h3>
                          </th>
                        </tr>
                      </thead>
                        <tbody>
                          <tr
                           
                          >
                            <td class="text-h5" >Total Accounts</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.TotalAccounts ? FinalData.credit_summary.equifax.TotalAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.TotalAccounts ? FinalData.credit_summary.experian.TotalAccounts  : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.TotalAccounts ? FinalData.credit_summary.transunion.TotalAccounts : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Open Accounts </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.OpenAccounts ? FinalData.credit_summary.equifax.OpenAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.OpenAccounts ? FinalData.credit_summary.experian.OpenAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.OpenAccounts ? FinalData.credit_summary.transunion.OpenAccounts : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Closed Accounts </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.CloseAccounts ? FinalData.credit_summary.equifax.CloseAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.CloseAccounts ? FinalData.credit_summary.experian.CloseAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.CloseAccounts ? FinalData.credit_summary.transunion.CloseAccounts : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Delinquent </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.DelinquentAccounts ? FinalData.credit_summary.equifax.DelinquentAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.DelinquentAccounts ? FinalData.credit_summary.experian.DelinquentAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.DelinquentAccounts ? FinalData.credit_summary.transunion.DelinquentAccounts : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5"> Derogatory </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.DerogatoryAccounts ? FinalData.credit_summary.equifax.DerogatoryAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.DerogatoryAccounts ? FinalData.credit_summary.experian.DerogatoryAccounts : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.DerogatoryAccounts ? FinalData.credit_summary.transunion.DerogatoryAccounts : 0}}</td>
                          </tr>
                          <tr
                            >
                              <td class="text-h5">Collections</td>
                              <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.Collection ? FinalData.credit_summary.transunion.Collection  : ''}}</td>
                              <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.Collection? FinalData.credit_summary.experian.Collection : ''}}</td>
                              <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.Collection ? FinalData.credit_summary.equifax.Collection  :''}}</td>
                              
                            </tr>
                          <tr
                          >
                            <td class="text-h5">Public Records</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.NumberOfRecords ? FinalData.credit_summary.equifax.NumberOfRecords  :0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.NumberOfRecords ? FinalData.credit_summary.experian.NumberOfRecords : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.NumberOfRecords ? FinalData.credit_summary.transunion.NumberOfRecords : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Inquires (2 years)</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.NumberInLast2Years ? FinalData.credit_summary.equifax.NumberInLast2Years : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.NumberInLast2Years ? FinalData.credit_summary.experian.NumberInLast2Years : 0}}</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.NumberInLast2Years ? FinalData.credit_summary.transunion.NumberInLast2Years : 0}}</td>
                          </tr>
         
                        </tbody>
                      </template>
                    </v-simple-table>
              </v-col>
              <v-col cols="12" md="4">
                   <v-simple-table style="margin-bottom:50px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#272727;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold text-white">Client Process Checklist</h2>
                          </th>

                        </tr>
                      </thead>
                        <tbody>
                          <tr
                           
                          >
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;">check</v-icon> 1 - Complete All Client Information</span> <span style="float:right;color:#2785CD;cursor:pointer;" @click="$router.push('/admin/edit-client/'+id)">Edit</span></td>
                          </tr>
                          <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  2 - Assign Company, Sales Person</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.status == 1">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  3 - Activate Client Portal Access</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  4 - Initiate On-Boarding Campaign</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  5 - Setup Billing Information</span> <span style="float:right;color:#2785CD">Edit</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="reports.length != 0">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  6 - Import Credit Report</span> <span style="float:right;color:#2785CD;cursor:pointer;" @click="importDialog = true">Import</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.progress >= 5">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  7 - Complete Round 1 Disputes</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon> 8 - Print / Send Letters</span> </td>
                          </tr>
                          </tbody>
                          </template>
                          </v-simple-table>
                          
              
              </v-col>
                </v-row>
              </v-card>
              </v-col>
              <v-col cols="12" md="12" v-if="this.$vuetify.breakpoint.xs">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-row>
                <v-col cols="12" >
                        
                <v-simple-table class="margin-bottom:150px;">
                    <template v-slot:default>
                      <thead style="background:#272727;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold white-text">Equifax</h2>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                        
                        >
                          <td style="background: white;    padding: 0px;"> <JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionseq" class="columnChart"></JSCharting>

                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>


                  <v-simple-table style="margin-bottom:50px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#EAF1F5;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold ">Status</h2>
                          </th>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold text-black">Equifax</h2>
                          </th>
                        </tr>
                      </thead>
                        <tbody>
                          <tr
                           
                          >
                            <td class="text-h5" >Total Accounts</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.TotalAccounts ? FinalData.credit_summary.equifax.TotalAccounts : 0}}</td>
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Open Accounts </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.OpenAccounts ? FinalData.credit_summary.equifax.OpenAccounts : 0}}</td>
                           
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Closed Accounts </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.CloseAccounts ? FinalData.credit_summary.equifax.CloseAccounts : 0}}</td>
                            
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Delinquent </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.DelinquentAccounts ? FinalData.credit_summary.equifax.DelinquentAccounts : 0}}</td>
                           
                          </tr>
                          <tr
                          >
                            <td class="text-h5"> Derogatory </td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.DerogatoryAccounts ? FinalData.credit_summary.equifax.DerogatoryAccounts : 0}}</td>
                             
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Public Records</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.NumberOfRecords ? FinalData.credit_summary.equifax.NumberOfRecords  :0}}</td>
                            
                          </tr>
                          <tr
                          >
                            <td class="text-h5">Inquires (2 years)</td>
                            <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.equifax.NumberInLast2Years ? FinalData.credit_summary.equifax.NumberInLast2Years : 0}}</td>
                           
                          </tr>
         
                        </tbody>
                      </template>
                    </v-simple-table>
              </v-col>
              <v-col cols="12" >
                        
                        <v-simple-table class="margin-bottom:150px;">
                            <template v-slot:default>
                              <thead style="background:#272727;">
                                <tr>
                                 
                                  <th class="text-left">
                                    <h3 class="text-h5 font-weight-bold white-text"> Experian </h3>
                                  </th>
                                    
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                
                                >
                                  
                                  <td style="background: white;    padding: 0px;"><JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionsex" class="columnChart"></JSCharting></td>
                                 
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
        
        
                          <v-simple-table style="margin-bottom:50px;" class="table-one">
                              <template v-slot:default>
                               <thead style="background:#EAF1F5;">
                                <tr>
                                  <th class="text-left">
                                   <h2 class="text-h5 font-weight-bold ">Status</h2>
                                  </th>
                                 
                                  <th class="text-left">
                                    <h3 class="text-h5 font-weight-bold text-black"> Experian </h3>
                                  </th>
                                    
                                </tr>
                              </thead>
                                <tbody>
                                  <tr
                                   
                                  >
                                    <td class="text-h5" >Total Accounts</td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.TotalAccounts ? FinalData.credit_summary.experian.TotalAccounts  : 0}}</td>
                                   
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Open Accounts </td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.OpenAccounts ? FinalData.credit_summary.experian.OpenAccounts : 0}}</td>
                                    
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Closed Accounts </td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.CloseAccounts ? FinalData.credit_summary.experian.CloseAccounts : 0}}</td>
                                   
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Delinquent </td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.DelinquentAccounts ? FinalData.credit_summary.experian.DelinquentAccounts : 0}}</td>
                                   
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5"> Derogatory </td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.DerogatoryAccounts ? FinalData.credit_summary.experian.DerogatoryAccounts : 0}}</td>
                                   
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Public Records</td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.NumberOfRecords ? FinalData.credit_summary.experian.NumberOfRecords : 0}}</td>
                                   
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Inquires (2 years)</td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.experian.NumberInLast2Years ? FinalData.credit_summary.experian.NumberInLast2Years : 0}}</td>
                                   
                                  </tr>
                 
                                </tbody>
                              </template>
                            </v-simple-table>
                      </v-col>
                      <v-col cols="12" >
                        
                        <v-simple-table class="margin-bottom:150px;">
                            <template v-slot:default>
                              <thead style="background:#272727;">
                                <tr>
                                  
                                    <th class="text-left">
                                    <h3 class="text-h5 font-weight-bold white-text">Transunion</h3>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                
                                >
                                 
                                  <td style="background: white;    padding: 0px;"><JSCharting style="width: 200px; height: 250px;margin: 0px auto" :options="chartOptionstu" class="columnChart"></JSCharting></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
        
        
                          <v-simple-table style="margin-bottom:50px;" class="table-one">
                              <template v-slot:default>
                               <thead style="background:#EAF1F5;">
                                <tr>
                                  <th class="text-left">
                                   <h2 class="text-h5 font-weight-bold ">Status</h2>
                                  </th>
                                  
                                    <th class="text-left">
                                    <h3 class="text-h5 font-weight-bold text-black">Transunion</h3>
                                  </th>
                                </tr>
                              </thead>
                                <tbody>
                                  <tr
                                   
                                  >
                                    <td class="text-h5" >Total Accounts</td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.TotalAccounts ? FinalData.credit_summary.transunion.TotalAccounts : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Open Accounts </td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.OpenAccounts ? FinalData.credit_summary.transunion.OpenAccounts : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Closed Accounts </td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.CloseAccounts ? FinalData.credit_summary.transunion.CloseAccounts : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Delinquent </td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.DelinquentAccounts ? FinalData.credit_summary.transunion.DelinquentAccounts : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5"> Derogatory </td>
                                   
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.DerogatoryAccounts ? FinalData.credit_summary.transunion.DerogatoryAccounts : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Public Records</td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.NumberOfRecords ? FinalData.credit_summary.transunion.NumberOfRecords : 0}}</td>
                                  </tr>
                                  <tr
                                  >
                                    <td class="text-h5">Inquires (2 years)</td>
                                    
                                    <td class="text-h5" style="text-align:center;">{{FinalData.credit_summary.transunion.NumberInLast2Years ? FinalData.credit_summary.transunion.NumberInLast2Years : 0}}</td>
                                  </tr>
                 
                                </tbody>
                              </template>
                            </v-simple-table>
                      </v-col>
              <v-col cols="12">
                   <v-simple-table style="margin-bottom:50px;" class="table-one">
                      <template v-slot:default>
                       <thead style="background:#272727;">
                        <tr>
                          <th class="text-left">
                           <h2 class="text-h5 font-weight-bold text-white">Client Process Checklist</h2>
                          </th>

                        </tr>
                      </thead>
                        <tbody>
                          <tr
                           
                          >
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;">check</v-icon> 1 - Complete All Client Information</span> <span style="float:right;color:#2785CD;cursor:pointer;" @click="$router.push('/admin/edit-client/'+id)">Edit</span></td>
                          </tr>
                          <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  2 - Assign Company, Sales Person</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.status == 1">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  3 - Activate Client Portal Access</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  4 - Initiate On-Boarding Campaign</span> </td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  5 - Setup Billing Information</span> <span style="float:right;color:#2785CD">Edit</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="reports.length != 0">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  6 - Import Credit Report</span> <span style="float:right;color:#2785CD;cursor:pointer;" @click="importDialog = true">Import</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.progress >= 5">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon>  7 - Complete Round 1 Disputes</span></td>
                            </tr>
                            <tr>
                            <td class="text-h7" ><span><v-icon class="material-icons-round" style="color:green;" v-if="client.company_id">check</v-icon> <v-icon class="material-icons-round" style="color:red;" v-else>close</v-icon> 8 - Print / Send Letters</span> </td>
                          </tr>
                          </tbody>
                          </template>
                          </v-simple-table>
                          
              
              </v-col>
                </v-row>
              </v-card>
              </v-col>
             
            </v-row>
            </v-tab-item>
             <v-tab-item
              :value="`document`"
            >
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
        <div class="d-flex align-center">
          <v-row>
            <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
            <v-col cols="12" md="3">
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Documents
            </h5>
            <p class="text-sm font-weight-light text-body mb-0">
              Here is the list of all required documents.
            </p>
          </v-col>
          <v-col cols="12" md="9">
          <v-btn
                elevation="0"
                @click="addNewFile()"
                :ripple="false"
                color="#4CAF50"
                class="
                  font-weight-normal
                  text-capitalize
                  float-right
                  ms-auto
                  btn-primary
                  py-3
                  px-6
                  ms-3
                "
                >Add New Document</v-btn
              >
               <v-btn
                elevation="0"
                @click="removeDocuments()"
                :ripple="false"
                color="red"
                class="
                  font-weight-normal
                  text-capitalize float-right
                  ms-auto
                  btn-primary
                  py-3
                  px-6
                  ms-3
                  mr-5
                "
                >Remove All Documents</v-btn
              >
              <!-- <v-btn
                elevation="0"
                @click="sendEmail(2)"
                :ripple="false"
                :loading="loadingImport"
                color="orange"
                class="
                  font-weight-normal
                  text-capitalize float-right
                  ms-auto
                  btn-primary
                  py-3
                  px-6
                  ms-3
                  mr-5
                "
                >Send Email</v-btn
              > -->
              <v-btn
                elevation="0"
                @click="sendLetterDialog = true"
                :ripple="false"
                color="blue"
                class="
                  font-weight-normal
                  text-capitalize float-right
                  ms-auto
                  btn-primary
                  py-3
                  px-6
                  ms-3
                  mr-5
                "
                >Send Certified Letters</v-btn
              >
              </v-col>
              </v-row>
        </div>
      </div>
                <v-card-text >
                              <v-list-item-subtitle class="text-left mt-2">
            <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'id'"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="doc_id_flag">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-if="!doc_id_flag">
                              fa-times
                            </v-icon> ID 
                            <v-btn
                              color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "

                              title="Download file"
                              @click="downloadFile(doc_id_value)"
                              style="float:right;box-shadow: none;"
                              :disabled="!doc_id_flag"
                              small>View
                            </v-btn>
                            <v-btn
                               color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              small
                              title="Upload file"
                              @click="onButtonClick('file_id')"
                            >
                              Upload
                            </v-btn>
                            <input
                              ref="file_id"
                              class="d-none"
                              type="file"
                              accept="image/*"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>
                       <v-list-item-subtitle class="text-left mt-1">
                         <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'bill'"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="doc_bill_flag">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-if="!doc_bill_flag">
                              fa-times
                            </v-icon> Bill <v-btn
                               color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "
style="float:right;box-shadow: none;"
                              @click="downloadFile(doc_bill_value)"
                              :disabled="!doc_bill_flag"
                              title="Download file"
                              small>View
                            </v-btn>
                            <v-btn
                               color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              small
                              title="Upload file"
                              @click="onButtonClick('file_bill')"
                            >
                              Upload
                            </v-btn>
                            <input
                              ref="file_bill"
                              class="d-none"
                              type="file"
                              accept="image/*"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-left mt-1">
                        <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'letter_1'"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="doc_letter_a_flag">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-if="!doc_letter_a_flag">
                              fa-times
                            </v-icon> SSN Card <v-btn
                              color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "
style="float:right;box-shadow: none;"
                              title="Download file"
                              @click="downloadFile(doc_letter_a_value)"

                              :disabled="!doc_letter_a_flag"
                              small>View
                            </v-btn>
                            <v-btn
                               color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              small
                              title="Upload file"
                              @click="onButtonClick('file_letter_a')"
                            >
                              Upload
                            </v-btn>
                            <input
                              ref="file_letter_a"
                              class="d-none"
                              type="file"
                              accept="application/pdf,image/*"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-left mt-1">
                        <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'letter_2'"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="doc_letter_b_flag">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-if="!doc_letter_b_flag">
                              fa-times
                            </v-icon> Letter 2 <v-btn
                              color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "
style="float:right;box-shadow: none;"
                              title="Download file"
                              @click="downloadFile(doc_letter_b_value)"
  
                              :disabled="!doc_letter_b_flag"
                              small>View
                            </v-btn>
                            <v-btn
                               color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              small
                              title="Upload file"
                              @click="onButtonClick('file_letter_b')"
                            >
                             Upload
                            </v-btn>
                            <input
                              ref="file_letter_b"
                              class="d-none"
                              type="file"
                              accept="application/pdf,image/*"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-left mt-1">
                        <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'letter_3'"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="doc_letter_c_flag">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-else>
                              fa-times
                            </v-icon> Letter 3 <v-btn
                              color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "
style="float:right;box-shadow: none;"
                              title="Download file"
                              @click="downloadFile(doc_letter_c_value)"
                              :disabled="!doc_letter_c_flag"
                              small>View
                            </v-btn>
                            <v-btn
                               color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              title="Upload file"
                              
                              small
                              @click="onButtonClick('file_letter_c')"
                            >
                             Upload
                            </v-btn>
                            <input
                              ref="file_letter_c"
                              class="d-none"
                              type="file"
                              accept="application/pdf,image/*"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>









                  <v-list-item-subtitle class="text-left mt-1" v-for="item in moreDocuments" v-bind:key="item.id">
                            <v-checkbox
                              class="checkbox-doc"
                              style="float:left;margin:0;padding:0;"
                              v-model="documentCheck"
                              :value="'letter_'+item.id"
                            ></v-checkbox>
                        <v-icon style="color:green;margin-right:15px;" v-if="item.file_content">
                              fa-check
                            </v-icon><v-icon style="color:red;margin-right:15px;" v-else>
                              fa-times
                            </v-icon> Letter {{item.id}} ({{item.file_full_name}})<v-btn
                              color="orange"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                  "
style="float:right;box-shadow: none;"
                              title="Download file"
                              @click="downloadFile(item.file_content)"
                              :disabled="!item.file_content"
                              small>View
                            </v-btn>
                            <v-btn
                              color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3
                                    mr-5
                                  "
style="float:right;box-shadow: none;"
                              title="Upload file"
                              small
                              @click="onButtonClick('file_letter_'+item.id)"
                            >
                              Upload
                            </v-btn>
                            <input
                              :ref="'file_letter_'+item.id"
                              class="d-none"
                              type="file"
                              accept="application/pdf,image/png, image/jpeg, image/bmp, image/gif"
                              @change="onFileChanged"
                            >
                      </v-list-item-subtitle>


                    <v-list-item-subtitle class="t mt-2"  v-if="addFileFlag">
                      <v-row class="mt-2">
                        <v-col cols="12"  sm="5" md="5" lg="5" >
                            <v-file-input
                                multiple
                                small-chips
                                v-model="newFilesUpload"
                                label="Choose File"
                              ></v-file-input>
                        </v-col>
                        <v-col cols="12"  sm="5" md="5" lg="5" >
                             <v-text-field
                             v-model="newFileName"
                                label="Document Name*"
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12"  sm="2" md="2" lg="2" >
                            <v-btn
                               color="#4CAF50"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    mr-5
                                    ms-3"

                              :disabled="loadingCustomeFile"
                              title="Upload file"
                              style="margin: 9px 0px 0px -6px;"
                              @click="uploadMultiple"
                            >
                                Upload
                            </v-btn>
                             <v-btn
                               color="#4CAF50"
                               outlined
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                    py-3
                                    px-6
                                    ms-3 "

                              :disabled="loadingCustomeFile"
                              title="Upload file"
                              style="margin: 9px 0px 0px -6px;"
                              @click="addFileFlag = false"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                </v-card-text>
              </v-card>
            </v-tab-item>

             <v-tab-item
              :value="`score`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Scores
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is the list of all Imported Credit Reports.
                      </p>
                    </v-col>
                  
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >
                            
                            <v-simple-table class="table">
                                            <template v-slot:default >
                                              <thead>
                                                <tr>
                                                  
                                                  
                                                  <th class="text-left">
                                                    Status
                                                  </th>
                                                  <th class="text-left">
                                                    Import Date
                                                  </th>
                                                   <th class="text-left">
                                                    Equifax Score
                                                  </th>
                                                   <th class="text-left">
                                                    Experian Score
                                                  </th>
                                                   <th class="text-left">
                                                    Transunion Score
                                                  </th>
                                                  <th class="text-left">
                                                    Report
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="item in reports"
                                                  :key="item.name"
                                                >
                                                 
                                                   
                                                  <td>
                                                            {{item.status}}
                                                            </td>
                                                            <td>{{ item.created_at }}</td>
                                                             <td>
                                                            {{getScore(item,'eq')}}
                                                            </td>
                                                             <td>
                                                            {{getScore(item,'ex')}}
                                                            </td>
                                                             <td>
                                                            {{getScore(item,'tu')}}
                                                            </td>
                                                            
                                                            
                                                            <td><a v-if="item.status == 'Pending'" :href="'/admin/credit-report/'+item.id" style="  text-decoration: none;color:teal;">Click to view</a></td>
                                                </tr>
                                              </tbody>
                                            </template>
                                          </v-simple-table>
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
              :value="`request`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Requests
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is the list of all submitted requests.
                      </p>
                    </v-col>
                     <v-col>
                      <v-btn
                                                    depressed
                                                    @click="selected_bot = $route.params.id,creditCheck()"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      mr-5
                                                      ms-3
                                                      mt-5
                                                      mb-5
                                                    "
                                                  >New Request</v-btn>
                    </v-col>
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >

                                      <v-simple-table class="table">
                                            <template v-slot:default >
                                              <thead>
                                                <tr>
                                                  <th class="text-left">
                                                    Name
                                                  </th>
                                                  
                                                  <th class="text-left">
                                                    Status
                                                  </th>
                                                  <th class="text-left">
                                                    Action
                                                  </th>
                                                  <th class="text-left">
                                                    Submitted Date
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="item in tasks"
                                                  :key="item.name"
                                                >
                                                  <td>{{ item.process_name }}</td>
                                                   
                                                  <td>
                                                            <span
                                                              v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                                                              style="color:red;"
                                                            >
                                                            Pending
                                                            </span>
                                                            <span
                                                             
                                                              v-if="item.round_4_status == 'Successful'"
                                                              style="color:blue;"
                                                            >
                                                            Finished
                                                            </span>
                                                            <span
                                                              v-if="item.round_1_key || item.round_2_key || item.round_3_key || item.round_4_key"
                                                              style="color:green;"
                                                            >
                                                            Started
                                                            </span>
                                                            </td>
                                                            <td><v-btn
                                                                  class="mx-2"
                                                                  dark
                                                                  @click="dialogDoc = true, selected_id = item.id"
                                                                  x-small
                                                                  v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                                                                  :color="'green'"
                                                                >
                                                                Start
                                                                </v-btn></td>
                                                            <td>{{ item.created_at }}</td>
                                                </tr>
                                              </tbody>
                                            </template>
                                          </v-simple-table>
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
              :value="`memo`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Memos
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is saved memo.
                      </p>
                    </v-col>
                    <v-col>
                      <v-btn
                                                    depressed
                                                    v-if="!memoFlag"  @click="memoFlag = true"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      mr-5
                                                      ms-3
                                                      mt-5
                                                      mb-5
                                                    "
                                                  >Edit Memo</v-btn>
                    </v-col>
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >

                            
                                  <div v-if="!memoFlag" class="memo-div">
                                    {{client.memo}}
                                  </div>
                                  <v-textarea
                                    v-if="memoFlag"
                                    filled
                                     rows="10"
                                    v-model="client.memo"
                                  ></v-textarea>
                                  
                                
                                                <!-- <h4 class="mt-2 mb-2" >
                                                    Edit Memo
                                                  </h4> -->
                                                  <v-btn
                                                    depressed
                                                    style="text-transform: none;"
                                                    color="#4CAF50"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      py-3
                                                      px-6
                                                      mr-5
                                                      ms-3
                                                    "
                                                    v-if="memoFlag"
                                                    @click="saveMemo"
                                                  >
                                                    Save
                                                  </v-btn>
                                                  <v-btn
                                                  style="text-transform: none;"
                                                    depressed
                                                    outlined
                                                    v-if="memoFlag"
                                                    color="#4CAF50"
                                                      class="
                                                        font-weight-normal
                                                        text-capitalize
                                                        ms-auto
                                                        btn-primary
                                                        py-3
                                                        px-6
                                                        ms-3
                                                      "
                                                    @click="memoFlag = false"
                                                  >
                                                    Cancel
                                                  </v-btn>
             

                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
              :value="`credit`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Credits
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is the list of all Credit.
                      </p>
                    </v-col>
                  
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >
                            <v-row>
            <v-col sm="4" cols="12">
           <v-card class="mb-6 card-shadow border-radius-xl py-4 mt-10" style="background-image: linear-gradient(195deg, #49a3f1, #1A73E8) !important; color: white !important;">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                  
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                    style="color:white!important;"
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                        text-white
                      "
                    >
                      Available Credits
                    </p>
                    <h1 style="color:white!important;" class=" text-primary font-weight-bolder text-h1">
                <span>{{credit_available}}</span>
              </h1>
                  </v-col>
                </v-row>
                <hr class="dark horizontal mt-3 mb-4" />
                <v-row class="px-4">
                  <v-col cols="12">
                    <p class="mb-0 text-body">
                    <v-btn
                elevation="0"
                dark
                @click="creditDialog = true, creditFlag='add'"
                :ripple="false"
                color="#ff5353"
                class="
                  font-weight-normal
                  bg-gradient-success
                  text-capitalize
                  ms-auto
                  py-3
                  px-6
                  ms-3
                "
                >Add Credit</v-btn
              ><v-btn
                elevation="0"
                dark
                @click="creditDialog = true, creditFlag='remove'"
                :ripple="false"
                color="red"
                class="
                  font-weight-normal
                  text-capitalize
                  float-right
                  ms-auto
                  py-3
                  px-6
                  ms-3
                "
                >Remove Credit</v-btn
              >
                    </p>
                  </v-col>
                </v-row>
              </v-card>
              </v-col>
               <v-col sm="4" cols="12">
           <v-card class="mb-6 card-shadow border-radius-xl py-4 mt-10" style="height: 197px;background-image: linear-gradient(195deg, #49a3f1, #1A73E8) !important; color: white !important;">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                    style="color:white!important;"
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Used Credits
                    </p>
                    <h1 style="color:white!important;" class=" text-primary font-weight-bolder text-h1">
                <span>{{credit_used}}</span>
              </h1>
                  </v-col>
                </v-row>
              </v-card>
              </v-col>
              </v-row>
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
              :value="`round-letter`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Round/Letters
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is the list of all Sent Round/Letters.
                      </p>
                    </v-col>
                  
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >

                            <!-- <v-data-table
                            :headers="headers_letter"
                            :options.sync="datatable_options_letter"
                            :items="letters"
                            loading-text="Loading... Please wait"
                            :page.sync="page_letter"
                            v-model="selectedItems"
                            show-select
                              :items-per-page="itemsPerPage_letter"
                            hide-default-footer
                            class="table"
                              >
                          
                              <template v-slot:item.print_status="{ item }">
                                  {{item.print_status == 1 ? 'Yes' :'No'}}
                              </template>
                              <template v-slot:item.email="{ item }">
                                  {{item.email ? item.email : 'Not Available'}}
                              </template>
                              <template v-slot:item.phone="{ item }">
                                  {{item.phone_m ? item.phone_m : "Not Avilable"}}
                              </template>
                              <template v-slot:item.date_of_birth="{ item }">
                                  {{dateFormat(item.dob)}}
                              </template>
                              <template v-slot:item.price="{ item }">
                                  {{'$'+item.price}}
                              </template>
                              <template v-slot:item.title="{ item }" >
                                <span style="cursor:pointer;color:blue;" > {{item.title}} </span>
                              </template>
                              <template v-slot:item.status="{ item }">
                                  {{item.status == 1 ? 'Active' :'Inactive'}}
                              </template>
                              <template v-slot:item.edit="{ item }">
                                  <v-btn
                                  class="mx-2"
                                  text
                                  dark
                                @click="editFlag = true, letter = item, loadingAssign = true"
                                  x-small
                                  :disabled="item.company_id == '1'"
                                  color="blue"
                                >
                                  <v-icon size="20" class="material-icons-round opacity-10" dark>
                                    editaa
                                  </v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:item.download="{ item }">
                                  <v-btn
                                  class="mx-2"
                                  text
                                  dark
                                  v-if="item.print_status == 1"
                                @click="viewLetter(item)"
                                  x-small
                                  color="blue"
                                >
                                  <v-icon size="20" class="material-icons-round opacity-10" dark>
                                    visibility
                                  </v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:item.password="{ item }">
                                  <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  @click="dialogDetail = true,selectedItem = item"
                                  x-small
                                  color="green"
                                >
                                  {{item.total_count}}
                                </v-btn>
                              </template>
                            
                              <template v-slot:item.address="{ item }">
                              <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                              </span>
                              </template>
                              <template v-slot:item.status_id="{ item }">
                                <v-btn
                                  class="mx-2"
                                  dark
                                  @click="changeStatus(item.user_id,item.status)"
                                  x-small
                                  :color="item.status==1 ? 'red':'green'"
                                >
                                {{item.status==1 ? 'Deactivate':'Activate'}}
                                </v-btn>
                              </template>
                              <template v-slot:item.document="{ item }">
                                {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                              </template>
                                <template v-slot:item.edit="{ item }">
                                  <v-btn
                                  class="mx-2"
                                  text
                                  :dark="item.company_id != '1' && role.name != 'super_admin'"
                                @click="openedit(item)"
                                  x-small
                                  :disabled="item.company_id == '1' && role.name != 'super_admin'"
                                  color="blue"
                                >
                                  <v-icon size="20" class="material-icons-round opacity-10" dark>
                                    edit
                                  </v-icon>
                                </v-btn>
                              </template>
                              <template v-slot:item.id="{ item }">

                                <v-btn
                                  class="mx-2"
                                  text
                                  dark
                                  @click="deleteItemLetter(item.id)"
                                  x-small
                                  color="red"
                                >
                                <v-icon size="20" class="material-icons-round opacity-10" dark>
                                    delete
                                  </v-icon>
                                </v-btn>
                              </template>
                              </v-data-table> -->
                              
                              
                              
                            <v-row class="mb-5">
                              <v-col cols="12" md="4" v-for="(letter,key) in letters">
                                <div>
                                <v-menu
                                      bottom
                                      origin="center center"
                                      transition="scale-transition"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          color="#FEEA00"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          Round {{key}}
                                        </v-btn>
                                      </template>

                                      <v-list>
                                        <v-list-item
                                        v-if="key == last_letter"
                                          key="1"
                                        >
                                          <v-list-item-title style="cursor:pointer;" @click="redoRound(letter[0])">Redo</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          key="2"
                                        >
                                        <v-list-item-title style="cursor:pointer;" @click="printRound(letter[0])">Print Round</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                          key="3"
                                        >
                                        <v-list-item-title style="cursor:pointer;" @click="sendLetterDialog = true">Mail Round</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                    <span class="ml-3">{{getFormattedDate(letter[0].created_at)}}</span>
                                  </div>
                                    <div class="mt-3" v-for="(letter_item) in letter">
                                      <span style="color:#1A73E8;"><strong>{{letter_item.title}}</strong>
                                        <span v-if="letter_item.print_status" class="ml-2"><v-icon size="20" color="green" class="material-icons-round opacity-10" @click="sendLetterDialog = true">
                                        mail
                                      </v-icon><v-icon  color="green" size="20" class="material-icons-round opacity-10 ml-2" @click="viewLetter(letter_item)">
                                        print
                                      </v-icon></span>
                                      <span v-else class="ml-2"><v-icon size="20" class="material-icons-round opacity-10">
                                        mail
                                      </v-icon><v-icon size="20" class="material-icons-round opacity-10 ml-2">
                                        print
                                      </v-icon></span>
                                </span>
                                    </div>
                              </v-col>
                            </v-row>
                              
                              
                              
                              
                              
                              
                              
                              
                              <!-- <div class="text-center pt-2 mt-5">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_letter"
                                :length="pageCountLetter"
                                @input="getLetters"
                            ></v-pagination>
                            </div> -->
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
              :value="`letter`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Certified Letters
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is the list of all Sent Letters.
                      </p>
                    </v-col>
                  
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >

                            <v-simple-table class="table">
                                            <template v-slot:default >
                                              <thead>
                                                <tr>
                                                  
                                                  
                                                  <th class="text-left">
                                                    Status
                                                  </th>
                                                  <th class="text-left">
                                                    Document
                                                  </th>
                                                  <th class="text-left">
                                                    Recipient
                                                  </th>
                                                  <th class="text-left">
                                                    Sender
                                                  </th>
                                                  <th class="text-left">
                                                    Date
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="item in certifiedLetters"
                                                  :key="item.name"
                                                >
                                                 
                                                   
                                                  <td>
                                                            {{item.status}}
                                                            </td>
                                                            <td>{{ item.document }}</td>
                                                            <td>{{ item.receiver }}</td>
                                                            <td>{{ item.sender }}</td>
                                                            <td>{{ item.created_at }}</td>
                                                </tr>
                                              </tbody>
                                            </template>
                                          </v-simple-table>
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
              <v-tab-item
              :value="`grants`"
            >
            <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <div class="card-header-padding">
                  <div class="d-flex align-center">
                    <v-row>
                      <v-col cols="12"><rotate-loader style="float:right; " :loading="loadingUpload" color="#1D75F4"></rotate-loader></v-col>
                      <v-col>
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">
                        Grants
                      </h5>
                      <p class="text-sm font-weight-light text-body mb-0">
                        Here is submitted grants.
                      </p>
                    </v-col>
                    <v-col>
                      
                                                  <v-btn
                                                    depressed
                                                    v-if="grant.length == 0 && applyGrantFlag == false"  @click="applyGrantFlag = true"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      mr-5
                                                      ms-3
                                                      mt-5
                                                      mb-5
                                                    "
                                                  >Apply for Grant</v-btn>
                    </v-col>
                        </v-row>
                        </div>
                      </div>
                          <v-card-text >
                              <v-form
                                ref="form3"
                                v-model="valid"
                                lazy-validation
                            >
                              <v-row class="mt-2" v-if="grant.length == 0 && applyGrantFlag == false">
                                 <v-col cols="12" class="py-0">
                                    <p class="text-md font-weight-light text-body mb-0 ml-3">
                                          No grant available.
                                        </p>
                                 </v-col>
                              </v-row>
                              <v-row  v-else>
                             <v-col cols="12" md="12" >
                              <HVStateStepper
                                :states="grantStates"
                                current-color="red accent-3"
                                current-icon="fa-bullseye"
                                previous-color="green"
                                previous-icon="fa-check"
                              />
                              </v-col>
                              </v-row>
                            <v-tabs v-if="grant.length != 0 || applyGrantFlag == true" background-color="transparent" v-model="model_grant" color="success">
                            <v-tabs-slider height="5px;" color="#4CAF50"></v-tabs-slider>
                            <v-tab href="#application">Grant Application</v-tab>
                            <v-tab v-if="grant.length != 0 " href="#grants">All Grants</v-tab>
                            <v-tab v-if="grant.length != 0 " href="#in-progress">To be complete</v-tab>
                            <v-tab v-if="grant.length != 0 " href="#completed">Completed</v-tab>
                          </v-tabs >
                          
                        <v-tabs-items v-model="model_grant" style="background-color: transparent;">
                                    <v-tab-item
                                      :value="`application`"
                                    >


                              <v-row class="mt-2" v-if="grant.length != 0 || applyGrantFlag == true">
                        <v-col cols="12" class="py-0">
                            <v-text-field
                              label="Grant Specific Email"
                              color="#e91e63"
                              required
                              :rules="rules"
                              
                              v-model="grant.grant_email"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-text-field
                              label="Grant Email Login Password"
                              color="#e91e63"
                              required
                              :rules="rules"
                              
                              
                              v-model="grant.grant_password"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                            :rules="rules"
                              label="OWNER FULL NAME "
                              color="#e91e63"
                              required
                              
                              
                              v-model="grant.grant_full_name"
                              class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_dob"
                                color="#e91e63"
                                :rules="rules"
                                
                                label="OWNER DATE OF BIRTH "
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <!-- <v-text-field
                                :rules="rules"
                                v-model="grant.grant_address"
                                color="#e91e63"
                                label="OWNER ADDRESS (City,State & Zip code)"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field> -->
                            <vuetify-google-autocomplete
                              id="map"
                              v-model="grant.grant_address"
                              label="OWNER ADDRESS (City,State & Zip code)"
                              class="font-size-input input-style"
                              v-on:placechanged="getAddressData"
                              country="us"
                          ></vuetify-google-autocomplete>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_gender" :rules="rules">
                              <template v-slot:label>
                                  <div>OWNER GENDER</div>
                                </template>
                                <v-radio value="Male">
                                  <template v-slot:label>
                                    <div>Male</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Female">
                                  <template v-slot:label>
                                    <div>Female</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Prefer not to say">
                                  <template v-slot:label>
                                    <div>Prefer not to say</div>
                                  </template>
                                </v-radio>
                                   <v-radio value="Other">
                                  <template v-slot:label>
                                    <div>Other</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_ethnicity" :rules="rules">
                              <template v-slot:label>
                                  <div>OWNERS ETHNICITY</div>
                                </template>
                                <v-radio value="WHITE">
                                  <template v-slot:label>
                                    <div>WHITE</div>
                                  </template>
                                </v-radio>
                                <v-radio value="BLACK/ AFRICAN AMERICAN">
                                  <template v-slot:label>
                                    <div>BLACK/ AFRICAN AMERICAN</div>
                                  </template>
                                </v-radio>
                                <v-radio value="NATIVE AMAERICAN/ INDEGENOUS PERSONS">
                                  <template v-slot:label>
                                    <div>NATIVE AMAERICAN/ INDEGENOUS PERSONS</div>
                                  </template>
                                </v-radio>
                                   <v-radio value="ASIAN">
                                  <template v-slot:label>
                                    <div>ASIAN</div>
                                  </template>
                                </v-radio>
                                <v-radio value="HISPANIC/ SPANISH">
                                  <template v-slot:label>
                                    <div>HISPANIC/ SPANISH</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Other">
                                  <template v-slot:label>
                                    <div>Other</div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_veteran" :rules="rules">
                              <template v-slot:label>
                                  <div>Are you a Veteran ?</div>
                                </template>
                                <v-radio value="Yes">
                                  <template v-slot:label>
                                    <div>Yes</div>
                                  </template>
                                </v-radio>
                                <v-radio value="No">
                                  <template v-slot:label>
                                    <div>No</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Prefer not to say">
                                  <template v-slot:label>
                                    <div>Prefer not to say</div>
                                  </template>
                                </v-radio>
                                
                              </v-radio-group>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-radio-group v-model="grant.grant_disabled" :rules="rules">
                              <template v-slot:label>
                                  <div>Are you Disabled?</div>
                                </template>
                                <v-radio value="Yes">
                                  <template v-slot:label>
                                    <div>Yes</div>
                                  </template>
                                </v-radio>
                                <v-radio value="No">
                                  <template v-slot:label>
                                    <div>No</div>
                                  </template>
                                </v-radio>
                                <v-radio value="Maybe">
                                  <template v-slot:label>
                                    <div>Maybe</div>
                                  </template>
                                </v-radio>
                                
                              </v-radio-group>
                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Business Informations</h5>
                          <v-divider></v-divider>
                          </v-col>
                           <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_start_date"
                                color="#e91e63"
                                :rules="rules"
                                label="Business Start Date"
                                placeholder="MM/DD/YYYY"
                                v-mask="'##/##/####'"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_name"
                                color="#e91e63"
                                :rules="rules"
                                label="Business Name"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <!-- <v-text-field
                                v-model="grant.grant_business_address"
                                color="#e91e63"
                                :rules="rules"
                                label="Business Address (City, State, Zip code) "
                                ref="dob"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field> -->
                            <vuetify-google-autocomplete
                              id="map2"
                              v-model="grant.grant_business_address"
                              label="Business Address (City, State, Zip code)"
                              class="font-size-input input-style"
                              v-on:placechanged="getAddressData2"
                              country="us"
                          ></vuetify-google-autocomplete>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_phone"
                                color="#e91e63"
                                :rules="rules"
                                label="Business Phone Number"
                                ref="dob"
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                            <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="grant.grant_business_website"
                                color="#e91e63"
                                :rules="rules"
                                label="Business Website"
                                ref="dob"
                                class="font-size-input input-style"
                            >
                            
                            </v-text-field>
                          </v-col>
                            <v-col cols="12" class="py-0">
                            <v-text-field
                            :rules="rules"
                                v-model="grant.grant_business_duns"
                                color="#e91e63"
                                label="Business D-U-N-S Number"
                                ref="dob"
                                v-mask="'###########'"
                                class="font-size-input input-style"
                            >
                            
                            </v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Business Tax Informations</h5>
                          <v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                            :rules="rules"
                                v-model="grant.grant_business_tax_id"
                                color="#e91e63"
                                
                                label="Business EIN / TAX ID"
                                ref="dob"
                                v-mask="'## #######'"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-text-field
                            :rules="rules"
                                v-model="grant.grant_business_employees"
                                color="#e91e63"
                                label="Number of Employees"
                                ref="dob"
                                v-mask="'########'"
                                class="font-size-input input-style"
                                
                            >
                            
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-select
                             :rules="rules"
                        color="#e91e63"
                        label="Annual Gross Income"
                        class="font-size-input input-style"
                                :items="['$0-$20,000','$20,000-$50,000','$50,000-$100,000','$100,000+']"
                                v-model="grant.grant_business_income"
                                
                                
                        ></v-select>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-textarea
                             :rules="rules"
                                v-model="grant.grant_business_income_explain"
                                color="#e91e63"
                                label="Income Explanation  (How does this business make money ?)"
                                ref="dob"
                                
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                             <v-text-field
                             :rules="rules"
                                v-model="grant.grant_business_expanses"
                                color="#e91e63"
                                label="Business Expenses  (List Business Bills and amounts)"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="py-0">
                              <v-file-input
                              
                                  v-model="grant.file_1"
                                  class="font-size-input input-style"
                                  label="Please Upload your Articles of Operation (LLC) "
                                >
                                <template v-slot:append-outer>
                                   
                                         <v-btn
                                                    depressed
                                                    small
                                                    accept="image/*,application/pdf"
                                                    :disabled="!grant.file_1"
                                                    @click="viewFile(grant.file_1)"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      btn-primary
  
                                                    "
                                                  >View</v-btn>
                                  </template></v-file-input>
                              

                          </v-col>
                           <v-col cols="12" class="py-0">
                              <v-file-input
                                  v-model="grant.file_2"
                                  accept="image/*,application/pdf"
                                  class="font-size-input input-style"
                                  label="Please Upload your EIN Form. "
                                >
                                <template v-slot:append-outer>
                                   
                                         <v-btn
                                                    depressed
                                                    small
                                                    @click="viewFile(grant.file_2)"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    :disabled="!grant.file_2"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      btn-primary
  
                                                    "
                                                  >View</v-btn>
                                  </template>
                                
                                </v-file-input>
                                

                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Funding Request.</h5>
                          <v-divider></v-divider>
                          </v-col>  
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_request_funding"
                                :rules="rules"
                                color="#e91e63"
                                label="How much is your business Requesting in funding?"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_funding_used_for"
                                color="#e91e63"
                                :rules="rules"
                                label="What would this funding be used for ?"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="py-0">
                          
                          <h5 class="font-weight-bolder text-h5 text-typo mb-0">Lets Get to know your business a bit more </h5>
                          <v-divider></v-divider>
                          </v-col>  
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_industry"
                                color="#e91e63"
                                :rules="rules"
                                label="What is your business Industry?"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-text-field
                                v-model="grant.grant_business_competitors"
                                color="#e91e63"
                                :rules="rules"
                                label="List your top 3 competitors "
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info"
                                color="#e91e63"
                                :rules="rules"
                                label="Tell us about your business ( start date, what your specialties are and how do you operate your business?)"
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info2"
                                color="#e91e63"
                                :rules="rules"
                                label="What sets your business aside from your competitors ?"
                                ref="dob"
                                class="font-size-input input-style"
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                                v-model="grant.grant_business_info3"
                                color="#e91e63"
                                :rules="rules"
                                label="Why should Grantors Choose your business ?"
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                           <v-col cols="12" class="py-0">
                             <v-textarea
                             
                                v-model="grant.grant_business_info4"
                                color="#e91e63"
                                label="How do you plan to grow your business in the next 3-5 years ? "
                                ref="dob"
                                class="font-size-input input-style"
                                
                            ></v-textarea>
                          </v-col>
                          <v-col>
                            <v-btn
                                                    depressed
                                                    :loading="loadingGrantApplication"
                                                      @click="$refs.form3.validate() ? submitGrantApplication()  : false"
                                                    style="text-transform: none;"
                                                    color="blue"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      ms-auto
                                                      btn-primary
                                                      float-right
                                                      py-3
                                                      px-6
                                                      mr-5
                                                      ms-3
                                                      mt-5
                                                      mb-5
                                                    "
                                                  >Save Grant Application</v-btn>
                          </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                        :value="`grants`"
                      >

                       <v-row class="mt-5">
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h5 font-weight-bold text-typo">Available Grants ({{totalCountAllGrants}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="6" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="dialogAssign2 = true"
                                    >
                                        Add Grant
                                    </v-btn>
                                     <v-btn
                                    color="red"
                                      class="
                                      ont-weight-bolder
                                        btn-default
                                        mr-5
                                        py-4
                                        px-8
                                        ms-auto
                                        mt-sm-auto mt-4
                                                      
                                                    "
                                        small
                                        @click="deleteGrant('','all')"
                                    >
                                        Delete Grant
                                    </v-btn>
                                     <v-btn
                                    color="teal"
                                    class="
                                    ont-weight-bolder
                                      btn-default
                                      mr-5
                                      py-4
                                      px-8
                                      ms-auto
                                      mt-sm-auto mt-4
                                                    
                                                  "
                                        small
                                        dark
                                        @click="dialogImportGrant = true"
                                    >
                                        Import from CSV
                                    </v-btn>
                                    <vue-json-to-csv :json-data="allGrants"
                                    :labels="{ name: { title: 'Grant Name' }, url : {title : 'Grant URL'}, deadline : {title : 'Deadline'},created_at : {title : 'Timestamp'} }"
                                    csv-title="grants"
                                    >
                                     <v-btn
                                    color="blue"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                    dark
                                        small
                                    >
                                        Export to csv
                                    </v-btn>
                                  </vue-json-to-csv>
                                    
                      </v-col>
                    <v-col cols="12"
                      md="6" >
                        <v-spacer></v-spacer>
                            <v-text-field
                               v-model="search"
                                label="Search"
                                single-line
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getUsers"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_grants"
                            :options.sync="datatable_options_grants"
                            :items="allGrants"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            show-select
                            v-model="selectGrant"
                            :items-per-page="itemsPerPage"
                            class="table"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
                        >
                     
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{getFormattedDate(item.created_at)}}
                        </template>
                         <template v-slot:item.deadline="{ item }">
                            {{item.deadline ? getFormattedDate(item.deadline) : 'N/A'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                         <template v-slot:item.apply="{ item }">
                            <v-btn
                            class="mx-2"
                            
                            dark
                           @click="applyGrant(item)"
                            x-small
                            color="blue"
                          >
                           
                            Apply
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            
                            icon
                           @click="deleteGrant(item)"
                            x-small
                            color="red"
                          >
                           
                               <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              key
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-company/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>

                        


                        <template v-slot:item.credit_new="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                            x-small
                            color="green"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              add_card
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.credit_remove="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              credit_card_off
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                      </v-tab-item>
                      <v-tab-item
                        :value="`in-progress`"
                      >

                       <v-row class="mt-5">
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h5 font-weight-bold text-typo">Applied Grants ({{totalCountInprogressGrants}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="6" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="markCompeted()"
                                    >
                                        Marked as Completed
                                    </v-btn>
                                    <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="deleteItemToBeCompleted('tobecom')"
                                    >
                                        Delete
                                    </v-btn>
                                    
                      </v-col>
                    <v-col cols="12"
                      md="6" >
                        <v-spacer></v-spacer>
                            <v-text-field
                               v-model="search2"
                                label="Search"
                                single-line
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getInGrants"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_grants_in"
                            :options.sync="datatable_options_grants_in"
                            :items="allGrantsIn"
                            loading-text="Loading... Please wait"
                            :page.sync="page_in"
                            v-model="selectedItemsToBeCompleted"
                            show-select
                            :items-per-page="itemsPerPage"
                            class="table"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
                        >
                     
                       
                        <template v-slot:item.created_at="{ item }">
                            {{getFormattedDate(item.created_at)}}
                        </template>
                         <template v-slot:item.submitted_date="{ item }">
                            {{item.submitted_date ? getFormattedDate(item.submitted_date) : 'N/A'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                         <template v-slot:item.apply="{ item }">
                            <v-btn
                            class="mx-2"
                            
                            dark
                           @click="applyGrant(item)"
                            x-small
                            color="blue"
                          >
                           
                            Apply
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              key
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-company/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>

                        


                        <template v-slot:item.credit_new="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                            x-small
                            color="green"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              add_card
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.credit_remove="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              credit_card_off
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            circle
                            color="#4CAF50"
                                v-model="page_in"
                                :length="pageCountIn"
                                @input="getInGrants"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                      </v-tab-item>
                      <v-tab-item
                        :value="`completed`"
                      >

                       <v-row class="mt-5">
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h5 font-weight-bold text-typo">Completed Grants ({{totalCountCompletedGrants}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="6" >
                      <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="deleteItemToBeCompleted('com')"
                                    >
                                        Delete
                                    </v-btn>
                         <!-- <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="markCompeted()"
                                    >
                                        Marked as Completed
                                    </v-btn> -->
                                    
                      </v-col>
                    <v-col cols="12"
                      md="6" >
                        <v-spacer></v-spacer>
                            <v-text-field
                               v-model="search3"
                                label="Search"
                                single-line
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getComGrants"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_grants_com"
                            :options.sync="datatable_options_grants_com"
                            :items="allGrantsCom"
                            loading-text="Loading... Please wait"
                            :page.sync="page_com"
                            v-model="selectedItemsCompleted"
                            show-select
                            :items-per-page="itemsPerPage"
                            class="table"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
                        >
                     
                       
                        <template v-slot:item.created_at="{ item }">
                            {{getFormattedDate(item.created_at)}}
                        </template>
                         <template v-slot:item.submitted_date="{ item }">
                            {{item.submitted_date ? getFormattedDate(item.submitted_date) : 'N/A'}}
                        </template>
                         <template v-slot:item.completed_date="{ item }">
                            {{item.submitted_date ? getFormattedDate(item.completed_date) : 'N/A'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                        <template v-slot:item.proof="{ item }">
                          <strong style="color:green;" v-show="item.proof" class="mr-2">Available</strong>
                          <strong  v-show="!item.proof" class="mr-2">Not Available</strong>
                          <v-btn
                              color="blue"
                                  class="
                                    font-weight-normal
                                    text-capitalize
                                    ms-auto
                                    btn-primary
                                  "
                              title="Upload file"
                              small
                              @click="onButtonClickProof(item.id)"
                            >
                            Upload proof of completion
                            </v-btn>
                            <input
                              :ref="'file_proof_'+item.id"
                              class="d-none"
                              type="file"
                              accept="application/pdf,image/png, image/jpeg, image/bmp, image/gif"
                              @change="onFileChangedProof"
                            >
                            <v-btn
                                                    depressed
                                                    small
                                                    @click="viewFile(item.proof)"
                                                    style="text-transform: none;"
                                                    color="orange"
                                                    :disabled="!item.proof"
                                                    class="
                                                      font-weight-normal
                                                      text-capitalize
                                                      btn-primary
                                                      ml-2
                                                    "
                                                  >View</v-btn>
                         
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                         <template v-slot:item.apply="{ item }">
                            <v-btn
                            class="mx-2"
                            
                            dark
                           @click="applyGrant(item)"
                            x-small
                            color="blue"
                          >
                           
                            Apply
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              key
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-company/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>

                        


                        <template v-slot:item.credit_new="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                            x-small
                            color="green"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              add_card
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.credit_remove="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              credit_card_off
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                             <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            circle
                            color="#4CAF50"
                                v-model="page_com"
                                :length="pageCountCom"
                                @input="getComGrants"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                      </v-tab-item>
                      
                    </v-tabs-items>
                    </v-form>
                          </v-card-text>
                      </v-card>
                      
            
            </v-tab-item>
            <v-tab-item
                            :value="`trade`"
                          >
                          <v-row style="height: 800px;">
                            <v-col cols="12" md="12">
  


                                  <iframe
                                        id="JotFormIFrame-90142788412154"
                                        title="Trainee Registration Form"
                                        onload="window.parent.scrollTo(0,0)"
                                        allowtransparency="true"
                                        allowfullscreen="true"
                                        allow="geolocation; microphone; camera"
                                        src="https://www.qandid.co/newapp/high-impact-credit-solutions/tradelines-109/3266?brokerconf=f7b7b4d113ec924ab236d37d74824b02&fbclid=IwAR2sC8TixiDI_zP1_bK3k9-DjgHpYutGhmrqhQZis2UQpvdha3MCXsL76M4"
                                        frameborder="0"
                                        style="width: 1px;
                                        min-width: 100%;
                                        height:100%;
                                        border:none;"
                                        scrolling="yes"
                                        ></iframe>

                                </v-col>
                                </v-row>
                                </v-tab-item>
          </v-tabs-items>
          
        </v-col>
        </v-row>
        <!-- </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels> -->
        <!-- <div
        v-if="panel2 != 0 && panel != 0"
        class="position-relative min-vh-100 mt-5"
        :style="company && loadingLogo &&  company.logo ? `background-image: url(${base_url_img+company.logo_background}); background-size: cover;` : `background-image: url(${backgroundLogo}); background-size: cover;` "
      ></div> -->
        </v-col>
      </v-row>
      <v-dialog
      v-model="importDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Import Credit Report
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <rotate-loader class="mt-3 mb-3" :loading="loadingImport" color="#1D75F4"></rotate-loader>
          <span style="color:#5D6167;">Import From</span>
          <v-select
                          v-model="import_from"
                          @change="renderImport"
                          outlined
                            :items="['Smart Credit','My Free Score Now','Html Source']"
                            
                          ></v-select>
          <span v-if="import_from != 'Html Source'" style="color:#5D6167;">Username</span>
          <v-text-field
          v-if="import_from != 'Html Source'"
                v-model="username"
                outlined
          ></v-text-field>
          <span v-if="import_from != 'Html Source'" style="color:#5D6167;">Password</span>
          <v-text-field
          v-if="import_from != 'Html Source'"
             type="password"
                v-model="password"
                outlined
          ></v-text-field>
          <span  v-if="import_from == 'Html Source'" style="color:#5D6167;">Source</span>
          <v-textarea
              v-if="import_from == 'Html Source'"
                v-model="username"
                outlined
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :dark ="isDarkImport"
            :disabled="isDisableImport"
            @click="getReportFromSmartCredit()"
          >
            Import
          </v-btn>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="importDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="sendLetterDialog"
    >

     <v-card style="background: #18214D;">
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="sendLetterDialog = false"
          >
            <v-icon class="ml-5">fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Send Letter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div >
          <v-form
                    ref="form"
                    v-model="valid_letter"
                    lazy-validation
                  >
               <v-row  style="padding: 50px;">
               
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5">
                        <div><v-radio-group v-model="radioGroup">
                                <v-radio
                                color="info"
                                  :label="`Upload a document`"
                                  :value="1"
                                ></v-radio>
                              </v-radio-group>
                        </div>
                        <div>
                          <v-btn
                          x-large
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="onButtonClick('file_letter')"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-upload
                              </v-icon>
                                 Click to upload PDF
                              
                            </v-btn>
                            <input
                              ref="file_letter"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChangedLetter"
                            >
                            Must be PDF format,
10mb max
                        </div>
                        <div class="mt-2">
                          {{docupost.file ? docupost.file.name : ''}} 
                            <v-icon class="ml-2" v-if="docupost.file.length != 0" color="red" @click="docupost.file = []">
                              fa-times
                            </v-icon>
                        </div>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                        <v-row>
                           <v-col cols="12" md="6">
                        <div style="margin:15px 0 7px 0;">
                          <h2>Mail service</h2>
                        </div>
                        <div>
                          <v-select
                          v-model="docupost.mail_service"
                            :items="['USPS First Class (3-10 days)','USPS Standard (5-20 days)']"
                            
                          ></v-select>
                          
                        </div>
                        </v-col>
                         <v-col cols="12" md="6">
                          <div style="margin:15px 0 7px 0;" v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <h2>Certified options</h2>
                        </div>
                        <div v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <v-select
                          v-model="docupost.certified_option"
                            :items="['','Certified mail','Certified w/electronic return receipt (proof of receipt)']"
                            
                          ></v-select>
                          
                        </div>
                         </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>To Recipient</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('res')"
                                v-model="address_select_res"
                                  :items="address_list_res"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="recipient.name"
                                  :rules="nameRules"
                                  label="Recipient name"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address2"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="recipient.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>From Sender</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('sen')"
                                v-model="address_select_sen"
                                  :items="address_list_sen"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="sender.name"
                                  :rules="nameRules"
                                  label="Sender name"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address2"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="sender.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>Document options</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col cols="12" md="3">
                           <v-checkbox
                                v-model="docupost.document_options.color"
                                :label="`Print in color `"
                              ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                              <v-checkbox
                                v-model="docupost.document_options.double_side"
                                :label="`Double-sided `"
                              ></v-checkbox>
                              </v-col>
                              </v-row>
                        </div>
                        </v-card-text>
                  </v-card>
                 </v-col>
                   <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                           <v-btn
                          x-large
                              :disabled="!valid_letter"
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="sendLetter"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-paper-plane
                              </v-icon>
                                 Send Letter
                              <rotate-loader style="" :loading="loadingSendLetter" color="white"></rotate-loader>
                            </v-btn>
                         </div>
                         </v-card-text>
                         </v-card>
                   </v-col>
              
               </v-row>
                </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssign2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
          Add new Grant
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Product
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Grant Name"
                v-model="product.name"
          ></v-text-field>
          <v-text-field
                v-model="product.url"
                label="Grant URL"
          ></v-text-field>
             <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="product.deadline"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="product.deadline"
                      label="Deadline"
                      prepend-icon="fa-calendar"
                      readonly
                      v-bind="attrs"
                      clearable
                      v-on="on"
                      @click:clear="product.deadline = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="product.deadline"
                    scrollable
                    color="green lighten-1"
                    :min="minDate"
                    header-color="green lighten-1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(product.deadline)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-select
                            item-text="name"
                            label="Focus Area"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.focus_area"
                            :items="focusarea"
                            ></v-select>
                             <v-select
                            item-text="name"
                            label="Location"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.location"
                            :items="locations"
                            ></v-select>
                            <v-text-field
                            label="Value"
                v-model="product.value"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!product.id"
            color="#4caf50"
            text
            :disabled="loadingAssign2"
            @click="addGrant()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogImport2"
      width="500"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Submit new request
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
                <span style="color:#5D6167;">Process</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.process"
                            :items="itemOptions"
                            ></v-select>
                <span style="color:#5D6167;">Round</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.round"
                            :items="[1,2,3,4]"
                            ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="submitRequest()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag"
          :opacity=".5"
        >
          <v-btn
            color="blue"
            class="mr-5"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditFlag = false,dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
    </v-dialog>
     <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        scrollable
        transition="dialog-bottom-transition"
      v-model="dialogSignContract"
      width="1000"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Sign Contract({{selectedContract.title}})
        </v-card-title>

        <v-card-text style="margin-top:30px;">
          


          <div v-html="renderHtml(selectedContract.letter)"></div>
          <v-checkbox v-model="checkbox">
          <template v-slot:label>
            <div>
              I, {{client.first_name}} {{client.last_name}} am giving my legal consent for my Electronic Signature to be used as proof that I did in fact sign this Electronic Document. I agree to all terms set forth herein.
              </div>
          </template>
        </v-checkbox>
          <div class="buttons-sig" v-if="checkbox">Signature</div>
          <div class="buttons-sig">
            
          <VueSignaturePad
          id="signature"
          scaleToDevicePixelRatio
          width="500px"
          v-if="checkbox"
          height="300px"
          ref="signaturePad"
          :options="options_pad"
        />
          </div>
        <div class="buttons-sig"  v-if="checkbox">
        <button @click="undo">Undo</button>
      </div>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
                text
                @click="submitContract()"
              >Agree to contract and Submit</v-btn>
              <v-btn
                text
                @click="dialogSignContract = false"
              >Close</v-btn>
           
            </v-card-actions>
        </v-card>
        
        </v-dialog>
        <v-dialog
          v-model="creditDialog"
          width="700"
        >
         <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{creditFlag == 'remove' ? 'Remove credit' : 'Add credit'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Credit</span>
          <v-text-field
                v-model="new_credit"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="addCredit()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="creditDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag2"
          :opacity=".5"
        >
          <v-btn
            color="primary"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditFlag2 = false,creditDialog = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
        </v-dialog>
        <v-dialog
        persistent
      v-model="dialogImportGrant"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Import client from CSV
        </v-card-title>

        <v-card-text>
           <rotate-loader class="mt-3" :loading="loadingUpload" color="#1D75F4"></rotate-loader>
           <h3 v-if="loadingUpload"> Importing...</h3>
          <v-file-input
              show-size
              class="mt-15"
              
              :disabled="loadingUpload"
              v-model="uploadedDocumentCSV"
              accept="application/csv"
              prepend-icon="fa-paperclip"
              label="Upload CSV"
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="uploadFiles()"
          >
            Upload
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload"
            @click="dialogImportGrant = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
    
    </v-app>
</template>

<script>
function labelText(series) {
        var value = series.points(0).options('y'),
          fgg = value >= 750 ? 'Great!' : value >= 630 ? 'Good' : 'Fair';
        return '<span >%sum</span><br/><span >' + fgg + '</span>';
      }
import HVStateStepper from '../HVStateStepper';
import JSCharting, { JSC } from 'jscharting-vue';
import Chart from "chart.js/auto";
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import VueJsonToCsv from 'vue-json-to-csv'
import RoundSlider from "vue-round-slider";
import moment from 'moment'
export default {
  components : {
   HVStateStepper,
   JSCharting,
   'RotateLoader': ClipLoader,VueJsonToCsv
    },
   data () {
      return {
        model: 'tab-2',
        switch1 : true,
        panel: 0,
        page_letter : 1,
        itemsPerPage_letter: 10,
        selectedContract : '',
        userContracts: [],
        last_letter : 0,
        datatable_options_letter: {},
        dialogSignContract : false,
        limit_letter: 10,
        newFilesUpload : [],
        panel3 : 0,
        panel4 : 0,
        options_pad: {
      penColor: "#00f",
    },
        wasabi_url : process.env.VUE_APP_WASBI_URL,
        grant : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                wasabi_url : process.env.VUE_APP_WASBI_URL.replace('/logo',''),
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no'

            },
        newFileName : '',
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
            { text: 'Round', value: 'round',
            sortable: true },
          { text: 'Print Staus', value: 'print_status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            // { text: 'Edit', value: 'edit',
            // sortable: true,width: '200' },
            { text: 'View', value: 'download',
            sortable: true,width: '200' },
          // { text: 'Delete', value: 'id',
          //   sortable: false,width: '50' },
        ],
        valid : true,
        uploadedDocumentCSV : [],
        loadingUpload : false,
        isDarkImport : true,
        totalCountAllGrants : 0,
        panel2: 1,
        totalCountInprogressGrants : 0,
        totalCountCompletedGrants : 0,
        dialogAssign2 : false,
        datatable_options_grants : {},
        address_select_sen : 'Custom',
        address_select_res : 'Custom',
        dialogImportGrant : false,
        datatable_options_grants_in : {},
        datatable_options_grants_com : {},
        product : {
          id : '',
          name : '',
          url : '',
          deadline : '',
          focus_area : '',
          location :'',
          value : 0
        },
        minDate: moment().format('YYYY-MM-DD'),
        settings : JSON.parse(localStorage.getItem('settings')),
        grant_count : 0,
        headers_grants : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Deadline', value: 'deadline',
            sortable: true
            },
            {
               text: 'Focus Area', value: 'focus_area_name',
            sortable: true
            },{
               text: 'Location', value: 'location_name',
            sortable: true
            },
            {
               text: 'Value', value: 'value',
            sortable: true
            },
          { text: 'Timestamp', value: 'created_at',
            sortable: true,width: '200' },
            { text: 'Action', value: 'apply',
            sortable: true,width: '200' },
         ],
         headers_grants_in : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
          
         ],
         headers_grants_com : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
            {
               text: 'Proof', value: 'proof',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
            { text: 'Completed At', value: 'completed_date',
            sortable: true,width: '200' },
         ],
        datatable_options_grants : [],
        allGrants : [],
        allGrantsIn : [],
        allGrantsCom : [],
        page_in : 1,
        page_com : 1,
        steps: 3, step: 1,
        import_from: 'Smart Credit',
        // isStepActive : "2",
        dialogStep: false,
        creditHistoryDialog : false,
        doc_count : 4,
        search: '',
        loading: false,
        addFileFlag: false,
        certifiedLetters : [],
        company_logo : localStorage.getItem('company_logo'),
        dialogUpload : false,
        limit : 10,
        applyGrantFlag : false,
        pageCount:1,
        pageCountIn:1,
        pageCountCom:1,
        backgroundLogo: localStorage.getItem('admin_bg'),
        itemsPerPage:10,
        avatar: '',
        letters : [],
        pageCountLetter : 0,
        selectedItemsToBeCompleted : '',
        selectedItemsCompleted : '',
        selectGrant : [],
        selectedItemsHistory : [],
        dialogAssign : false,
        src : "https://app.idealergy.com/v2/preview/t3AjaHbWdNQMrU5pWaxi?notrack=true",
        memoFlag : false,
        client: '',
        docs : [],
        datatable_options_history : {},
        page : 1,
        focusarea : [],
        locations: [],
        role: JSON.parse(localStorage.getItem('role')),
        tasks : [],
        doc_id_flag : false,
        doc_id_value : '',
        sendLetterDialog : false,
        rules: [
                value => !!value || 'Required.',
            ],
        moreDocuments : [],
        history : [],
        headers_history: [
          { text: 'History', value: 'history',
            sortable: true },
          { text: 'Transaction Id', value: 'transaction_id',
            sortable: true },
            {
               text: 'Gateway', value: 'transaction_from',
            sortable: false
            },
          { text: 'Created At', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated At', value: 'updated_at',
            sortable: true,width: '200' },
            

        ],
        doc_bill_flag : false,
        dialogImport2 : false,
        creditFlag2: false,
        loadingUpload2 :false,
        loadingUpload :false,
        contract : [],
        valid_letter : true,
         nameRules: [
        v => !!v || '',
      ],
        radioGroup : 1,
        credentials : [],
        selectedItems: [],
        selected_bot : '',
        checkbox : false,
        loadingSendLetter : false,
        docupost : {
          file : [],
          document_options : {
            color : 0,
            double_side : 0
          },
          mail_service: 'USPS First Class (3-10 days)',
          certified_option:'',
        },
        recipient : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        sender : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        documentCheck : [],
        selectedCountries: [],
        countries: [],
        isLoading: false,
        selectedFile : '',
        menu: false,
        dialog:false,
        loadingCustomeFile : false,
        reports: [],
        credit_available : 0,
        creditDialog:false,
        loadSignature: false,
        model_grant : '',
        search2 : '',
        search3 : '',
        creditFlag: 'add',
        importDialog: false,
        source_code: '',
        username : '',
        password : '',
        dialogDoc: false,
        value: null,
        loadingGrantApplication : false,
        options: ['list', 'of', 'options'],
        selected_id:'',
        new_credit: 0,
        loadingAssign: false,
        credit_used: 0,
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft','Mailing Letters'],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        creditFlag :false,
        
        doc_bill_value : '',
        doc_letter_a_flag : false,
        doc_letter_a_value : '',
        doc_letter_b_flag : false,
        doc_letter_b_value : '',
        doc_letter_c_flag : false,
        doc_letter_c_value : '',
        width : 100,
        currentState : "",
        importDialog : false,
        dashboard  : {
          score_eq : 0,
          score_ex : 0,
          score_tu : 0,

        },
        isDisableImport : false,
         FinalData: {
          profile : {
                score : {
                  transunion : 0,
                  experian : 0,
                  equifax : 0,
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {TotalAccounts :"",
                                  OpenAccounts :"",
                                  CloseAccounts :"",
                                  DelinquentAccounts :"",
                                  DerogatoryAccounts :"",
                                  NumberOfRecords :"",
                                  NumberInLast2Years :""},
                  experian : {TotalAccounts :"",
                                  OpenAccounts :"",
                                  CloseAccounts :"",
                                  DelinquentAccounts :"",
                                  DerogatoryAccounts :"",
                                  NumberOfRecords :"",
                                  NumberInLast2Years :""},
                  equifax : {TotalAccounts :"",
                                  OpenAccounts :"",
                                  CloseAccounts :"",
                                  DelinquentAccounts :"",
                                  DerogatoryAccounts :"",
                                  NumberOfRecords :"",
                                  NumberInLast2Years :""},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        },
        isDarkImport : true,
        trade_flag : false,
        client : {progress  : 1},
        chartConsumption: "chart-consumption",
        chartOptionseq: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionsex: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionstu: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
        grantStates : [],
        testStates: [
      { name: 'Customer Details',current: false,done : false },
      { name: 'Import Report',current: false,done : false },
      { name: 'Required Documents',current: false,done : false },
      { name: 'Agreement Signed',current: false,done : false },
      { name: 'Payment Made',current: false,done : false },
      { name: 'Resolution Round 1',current: false,done : false },
      { name: 'Resolution Round 2',current: false,done : false },
      { name: 'Resolution Round 3',current: false,done : false },
      { name: 'Resolution Round 4',current: false,done : false },
    ],
    dialogDoc : false,
        role: JSON.parse(localStorage.getItem('role')),
        tasks : [],
        doc_id_flag : false,
        src: "https://app.idealergy.com/v2/preview/t3AjaHbWdNQMrU5pWaxi?notrack=true",
        doc_id_value : '',
        doc_bill_flag : false,
        dialog:false,
        dialogImport2 : false,
        dialogImport : false,
        loadingUpload : false,
        loadingUpload2 :false,
        loadingImport : false,
        isDarkImport : true,
        selected_id:'',
        selected_bot : '',
        selectedItems:[],
        sitekey: process.env.MIX_RECAPTCHA_SITE_KEY,
        id: this.$route.params.id,
        selectedFile : '',
        selectedFileProof :'',
        credit_available : 0,
        creditDialog:false,
        source_code:'',
        creditFlag: 'add',
      address_list_res : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
      address_list_sen : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
        new_credit: 0,
        reports: [],
        username : '',
        password : '',
        loadingAssign: false,
        credentials : '',
        credit_used: 0,
        importDialog: false,
        loadingAssign2 : false,
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft','Mailing Letters'],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        creditFlag :false,
        
        doc_bill_value : '',
        doc_letter_a_flag : false,
        doc_letter_a_value : '',
        doc_letter_b_flag : false,
        doc_letter_b_value : '',
        doc_letter_c_flag : false,
        doc_letter_c_value : '',
      }
    },
    computed: {
    documentCheckAll : {
      set(val) {
        this.documentCheck  = []
        if (val) {
           this.documentCheck.push('id');
           this.documentCheck.push('bill');
           this.documentCheck.push('letter_1');
           this.documentCheck.push('letter_2');
           this.documentCheck.push('letter_3');
          for(let i = 1; i <= this.moreDocuments.length; i++) {
            let id = 3 + i;
            this.documentCheck.push('letter_'+id)
          }
        }
      },
      get() {
        console.log(this.documentCheck.length);
        console.log(this.moreDocuments.length + 5);
        // if(this.documentCheck.length !== (this.moreDocuments.length + 5))
        //   this.documentCheckAll = [];
        // return this.documentCheck.length != 0 && this.documentCheck.length === (this.moreDocuments.length + 5)
      }
    },
    
  },
    methods : {
      
      generateChart(){
        new Chart(document.getElementById(this.chartConsumption).getContext("2d"), {
             
  debug: true, 
  type: 'gauge ', 
  legend_visible: false, 
  chartArea_boxVisible: false, 
  xAxis: { 
    /*Used to position marker on top of axis line.*/
    scale: { range: [0, 1], invert: true } 
  }, 
  palette: { 
    pointValue: '%yValue', 
    ranges: [ 
      { value: 350, color: '#FF5353' }, 
      { value: 600, color: '#FFD221' }, 
      { value: 700, color: '#77E6B4' }, 
      { value: [800, 850], color: '#21D683' } 
    ] 
  }, 
  yAxis: { 
    defaultTick: { padding: 13, enabled: false }, 
    customTicks: [600, 700, 800], 
    line: { 
      width: 15, 
      breaks_gap: 0.03, 
      color: 'smartPalette'
    }, 
    scale: { range: [350, 850] } 
  }, 
  defaultSeries: { 
    opacity: 1, 
    shape: { 
      label: { 
        align: 'center', 
        verticalAlign: 'middle'
      } 
    } 
  }, 
  series: [ 
    { 
      type: 'marker', 
      name: 'Score', 
      shape_label: { 
        text: 
          "720<br/> <span style='fontSize: 35'>Great!</span>", 
        style: { fontSize: 48 } 
      }, 
      defaultPoint: { 
        tooltip: '%yValue', 
        marker: { 
          outline: { 
            width: 10, 
            color: 'currentColor'
          }, 
          fill: 'white', 
          type: 'circle', 
          visible: true, 
          size: 30 
        } 
      }, 
      points: [[1, 720]] 
    } 
  ] 

          });
      },
      getAddressData: function (addressData, placeResultData, id) {
            this.grant.grant_address = addressData.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
      creditCheck(){
        this.getUserProfileParent();
        

      },
      onCaptchaVerified: function (recaptchaToken) {
        this.request.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        this.request.recaptcha = ''
        },
    onButtonClick(id) {
      this.$refs[id].click()
      this.selectedFile = id;
    },
    onButtonClickProof(id) {
      this.$refs['file_proof_'+id].click()
      this.selectedFileProof = id;
    },
    getScore(item,flag){
      
      let data = JSON.parse(item.report);
      
      if(item.import_from == 'source'){
        if(flag == 'tu')
          return data[3][1][1][1]
        if(flag == 'ex')
          return data[3][1][1][2]
        if(flag == 'eq')
          return data[3][1][1][3]
      }else{
        if(flag == 'tu')
          return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
        if(flag == 'ex')
          return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
        if(flag == 'eq')
          return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
      }
    },
    applyGrant(item){
      if(confirm('Are you sure, You want to apply for this grant?')){
        this.loading = true;
        axios
              .post("/api/apply-grant",{user_id : this.$route.params.id,grant_id : item.id},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {

                if(response.data.flag == 1){
                  Vue.$toast.success('You have applied successfully', {
                      position: 'top-right'
                      });
                      this.loading = false;
                    this.getUsers();
                  }else{
                      Vue.$toast.error(response.data.message, {
                      position: 'top-right'
                      });
                  }
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
      }
    },
    viewLetter(item){
      window.open(process.env.VUE_APP_WASBI_URL+'letters/'+item.id+'_'+item.type+'.pdf', '_blank');
    },
    printRound(item){
      window.open(process.env.VUE_APP_WASBI_URL+'letters/'+item.credit_report_id+'.pdf', '_blank');
    },
    redoRound(item){
      if(confirm('Are you sure, You want to redo the round?')){
        this.$router.push('/admin/credit-report/'+item.credit_report_id);
      }
    },
    renderImport(){
      if(this.import_from=="Smart Credit"){
        this.username = this.client.smart_credit_user_name
        this.password = atob(this.client.smart_credit_password)
      }else{
        this.username = this.client.my_free_score_user_name
        this.password = atob(this.client.my_free_score_password)
      }
    },
    getUserProfileParent() {
        let flag = 1;
        let self = this;
        self.loading = true;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .get("/api/user",header)
            .then(response => {
                let data = response.data;

              if (flag == 1) {
                  this.user = data.user;
                  let settings = localStorage.getItem('settings');
                  settings = JSON.parse(settings);
                  this.setting = settings;
                  let item = 1;
                 
                  if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item)){
                    this.creditFlag = true;
                    this.dialogImport2 = true;
                    
                  }else{
                    this.dialogImport2 = true;
                    this.creditFlag = false;
                    
                  }
                  self.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                    self.loading = false;
                }

            })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });

        },
        viewFile(file){
          console.log(file);
            window.open(process.env.VUE_APP_WASBI_URL+file, '_blank');
        },
        getFormattedDate(date){
          return moment(date).format('MM/DD/YYYY');
        },
        clientLogin(){
          if(confirm('Are you sure, You want to login as a client?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client",
              data: {
                email: this.client.email              }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("from_admin",1);
                  localStorage.setItem("from_admin_email",localStorage.getItem('email'));
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("document_flag",response.data.doc_flag);
                  localStorage.setItem("agreement_flag",result.agreement_flag);
                  localStorage.setItem("is_admin",0);
                  localStorage.setItem("role", JSON.stringify({name : 'client'}));
                  
                  if(localStorage.getItem('temp_request_id'))
                    this.$router.push("/restart-process/"+localStorage.getItem('temp_request_id'));
                  if(localStorage.getItem("agreement_flag") == 1)
                    this.$router.push("/dashboard");
                  else
                    this.$router.push("/dashboard");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
        },
        uploadFiles(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentCSV.length == 0){
                Vue.$toast.error('Select document', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                self.loadingUpload = true;
                const data = new FormData();
                data.append('client_csv', self.uploadedDocumentCSV);
                axios({
                method: "POST",
                url: "/api/import-grant-csv",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                self.loadingUpload = false;
                 Vue.$toast.success('Grants imported successfully', {
                            position: 'top-right'
                            });
                self.getUsers();
                self.uploadedDocumentCSV = [];
                self.dialogImportGrant = false;
                // this.isDisableUpload = false;
                // this.isDarkUpload = true;
                // self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    self.loadingUpload = false;
                    // this.isDisableUpload = false;
                    // this.isDarkUpload = true;
                });
            }
        },
        sendEmail (flag){
      let self = this;
      let items = [];
      if(confirm('Are you sure, You want to send email with all documents?')){
      if(flag == 1){
        items = self.docs;
      }else{console.log(this.docs);
        this.docs.forEach(val => {
          console.log(val.file_name);
          console.log(self.documentCheck);
          if(val.file_name.includes('ID') && self.documentCheck.includes('id'))
            items.push(val)
          else if(val.file_name.includes('Bill') && self.documentCheck.includes('bill'))
            items.push(val)
          else if(val.file_name.includes('Letter_A') && self.documentCheck.includes('letter_1'))
            items.push(val)
          else if(val.file_name.includes('Letter_B') && self.documentCheck.includes('letter_2'))
            items.push(val)
          else if(val.file_name.includes('Letter_C') && self.documentCheck.includes('letter_3'))
            items.push(val)
          else if(self.documentCheck.includes(val.file_name.split('(')[0]))
            items.push(val)
        })
      }
      self.loadingImport = true;
      self.loadingUpload = true;
      axios.post("/api/send-client-email",{client_id : this.$route.params.id ,items : items},this.header)
                .then(response => {
                  self.loadingImport = false
                  self.loadingUpload = false
                  Vue.$toast.success('Email sent successfully', {
                            position: 'top-right'
                            });

                });
                
      }
    },
    renderAddress(flag){
      if(flag == 'res'){
        this.address_list_res.forEach(val=>{
          if(val.name == this.address_select_res){
            this.recipient.name = val.address_1;
            this.recipient.address1 = val.address_1;
            this.recipient.address2 = val.address_2;
            this.recipient.city = val.city;
            this.recipient.state = val.state;
            this.recipient.zip = val.zip;
          }
        })
      }else{
        this.address_list_sen.forEach(val=>{
          if(val.name == this.address_select_sen){
            this.sender.name = val.address_1;
            this.sender.address1 = val.address_1;
            this.sender.address2 = val.address_2;
            this.sender.city = val.city;
            this.sender.state = val.state;
            this.sender.zip = val.zip;
          }
        })
      }
    },
    prodigyPost(){
      alert()
    },
    markCompeted(){
      if (confirm('Are you sure, You want to mark as completed?')) {
                    axios
                .post("/api/marked-completed-grant",{ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Grant status changed successfully', {
                            position: 'top-right'
                            });
                            this.getInGrants();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }
    },
sendLetter(){
      let self = this;
      
      if(this.docupost.file.length == 0){
        Vue.$toast.error("Document is required! ", {
          position: 'top-right'
          });
      }else if(this.$refs.form.validate()){
        self.loadingSendLetter = true;
        const data = new FormData();
        data.append('file_letter', this.docupost.file);
        data.append('docupost', JSON.stringify(this.docupost));
        data.append('sender', JSON.stringify(this.sender));
        data.append('recipient', JSON.stringify(this.recipient));
        data.append('user_id', this.$route.params.id);
        axios({
          method: "POST",
          url: "/api/send-letter-client",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => { 
            self.loadingSendLetter = false;
            if(response.data.flag == 1){
               Vue.$toast.success('Your letter has been submitted', {
                    position: 'top-right'
                    });
                    this.$refs.form.reset()
                    this.docupost = {
                        file : [],
                        document_options : {
                          color : 0,
                          double_side : 0
                        },
                        mail_service: 'USPS First Class (3-10 days)',
                        certified_option:'',
                      }
            }else{
                    Vue.$toast.error(response.data.status, {
                    position: 'top-right'
                    });
            }
          })
           .catch(err => {
            self.loadingSendLetter = false;
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
          })
      }
    },
    submitGrantApplication(){
      const data = new FormData();
      console.log(this.grant);
      data.append('user_id', this.$route.params.id);
      data.append('file_1', this.grant.file_1);
      data.append('file_2', this.grant.file_2);
      data.append('grant',JSON.stringify(this.grant));
      this.loadingGrantApplication = true
      axios({
      method: "POST",
      url: "/api/submit-grant-application",
      data:data,
      headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
      })
      .then(response => {
        this.loadingGrantApplication = false
          if (response.data) {
                        Vue.$toast.success(' Application submitted successfully', {
                            position: 'top-right'
                            });
          }
      })
      .catch(err => {
        this.loadingGrantApplication = false
          Vue.$toast.error(err, {
          position: 'top-right'
          });
      })
    },
    loadSig(){
      let self = this
      setTimeout(function(){
        self.loadSignature = true
      },1000)
      
    },
    viewContract(id){
      let url = process.env.VUE_APP_WASBI_URL
      window.open(url+this.id+'_'+id+'.pdf', '_blank');
    },
     StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {

              this.loadingUpload2 = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                            this.getUserProfile();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        submitContract(){
        let data = this.$refs.signaturePad.saveSignature();
        if(!data.data){
          Vue.$toast.error('Please sign to submit the contract', {
                    position: 'top-right'
                    });
        }else{
          axios({
          method: "POST",
          url: "/api/submit-user-contract/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {
            data : data,
            template : this.selectedContract.id
          }
          })
          .then(response => { 
            if(response.data.flag == 1){
               Vue.$toast.success('Your Signed Contract has been submitted', {
                    position: 'top-right'
                    });
                    this.getContracts();
                    this.dialogSignContract = false;
                  }

                })
                
        }
      },
      undo() {
      this.$refs.signaturePad.undoSignature();
    },
    removeContract(id) {
      if(confirm('Are you sure? You want to remove.')){
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/remove-user-contract",
          data: {
            user_id : this.id,
            template : id
          }
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Contract removed successfully', {
                      position: 'top-right'
                      });
                      this.userContracts = [];
                      this.getContracts()
                    }
                  })
      }
    },
        addGrant(){
        
        if(!this.product.name){
        Vue.$toast.error("Name is required! ", {
          position: 'top-right'
          });
      }else if(!this.product.url){
        Vue.$toast.error("URL is required! ", {
          position: 'top-right'
          });
      }else{
        this.loadingAssign2 = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-new-grant",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Grant added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign2 = false;
                      this.loadingAssign2 = false;
                      this.getUsers();
                      this.product.name = '';
                      this.product.url = '';
                      this.product.deadline = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          }
        },
        addCreditCheck(){
          if(this.credentials.type == 'Jotform')
            this.dialogAssign = true
          else
            this.$router.push('/admin/pricing');
        },
    StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {

              this.loadingUpload2 = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                            this.getUserProfile();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        importReport(){
          axios({
          method: "POST",
          url: "/api/import-report",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {source: this.source_code}
          })
          .then(response => {
          console.log(response);
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
        },
        addNewFile(){
      let id = 4;
      this.addFileFlag = true;
    },
    onFileChanged(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      // if(!selectedFile.name.includes(self.client.email)){
      //   Vue.$toast.error("Document should contain user email", {
      //         position: 'top-right'
      //         });
      // }else{
      const data = new FormData();
          if(self.selectedFile == 'file_id')
            data.append('file_id', selectedFile);
          if(self.selectedFile == 'file_bill')
            data.append('file_bill', selectedFile);
          if(self.selectedFile == 'file_letter_a')
            data.append('file_letter_a', selectedFile);
          if(self.selectedFile == 'file_letter_b')
            data.append('file_letter_b',selectedFile);
          if(self.selectedFile == 'file_letter_c')
            data.append('file_letter_c',selectedFile);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.$route.params.id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    onFileChangedProof(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      const data = new FormData();
        data.append('file_proof',selectedFile);
        data.append('id',this.selectedFileProof);
          axios({
          method: "POST",
          url: "/api/upload-file-proof",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getComGrants();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    getReportFromSmartCredit(){
          if(this.import_from == 'Smart Credit'){
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-smart-credit",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
          }
        }else if(this.import_from == 'Html Source'){
          this.getReportFromIQ();
        }else{
          this.getReportFromMyfreescorenow();
        }
        },
        getReportFromMyfreescorenow(){
          
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-report-myfreescorenow",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Can't import as invalid credentials", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
              
          });
          }
        },
        getReportFromIQ(){
          
          if(!this.username){
            Vue.$toast.error("Source is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-report-iq",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Can't import as invalid credentials", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
              
          });
          }
        },
    getCompanySettings(){
       axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.credentials = data.credentials;
            }
            })
    },
    getFocusArea(){
       axios({
          method: "POST",
          url: "/api/get-all-focus-area",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.focusarea = data;
            }
            })
    },
    getLocation(){
       axios({
          method: "POST",
          url: "/api/get-all-location",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.locations = data;
            }
            })
    },
    renderHtml(html){
      if(html){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        html = html.replace("{client_suffix}",this.client.suffix);
        html = html.replace("{client_first_name}",this.client.first_name);
        html = html.replace("{client_middle_name}",(this.client.middle_name ? this.client.middle_name : ''));
        html = html.replace("{client_last_name}",this.client.last_name);
        html = html.replace("{client_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{client_previous_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{bdate}",this.client.dob);
        html = html.replace("{ss_number}",this.client.ssn);
        html = html.replace("{t_no}",this.client.phone_m);
        html = html.replace("{curr_date}",today);
        html = html.replace("{client_signature}",'');
      }

      return html;
    },
     getUserCredit() {
    let flag = 1;
    let self = this;

    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;
            

           if (flag == 1) {
              localStorage.setItem('credit',data.user.credit);
              
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
                self.loading = false;
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
      saveMemo(){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let id  = this.$route.params.id;
        axios
          .post("/api/save-client-memo",{
            memo : this.client.memo,
            user_id : id
          },header)
          .then(response => {
            this.memoFlag = false
          })
          .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
      },
      downloadFile(key){
      let self = this;
      let url = process.env.VUE_APP_WASBI_URL.replace('/logo','')
      this.docs.forEach(val =>{
        if(val.file_name == key){
          window.open(url+val.folder_id+'/'+val.file_name, '_blank');
        }
      })
                // axios({
                //     method: "POST",
                //     url: "/api/download-file",
                //     responseType: 'blob',
                //     headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                //     data: {key : key,user_id : this.$route.params.id}
                //     })
                //     .then(response => {
                //       console.log(response);
                //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                //    var fileLink = document.createElement('a');

                //    fileLink.href = fileURL;
                //    fileLink.setAttribute('download', Date.now());
                //    document.body.appendChild(fileLink);

                //    fileLink.click();
                    
                //     self.isDisableDownload = false;
                //     self.isDarkDownload = true;
                //     })
                //     .catch(err => {
                //         Vue.$toast.error(err, {
                //         position: 'top-right'
                //         });
                //     });
      },
      submitRequest(){
            let self = this;
            let flag = 1;
            let url ='';

                  self.request.multiple_flag = 0
                  url = "/api/user-add-request-admin/"+this.selected_bot
                

                this.loadingUpload2 = true;
                axios({
                method: "POST",
                url: url,
                data:self.request,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success != "False"){
                        // self.$router.push('/thanks');
                        Vue.$toast.success('Request submitted', {
                            position: 'top-right'
                            }); 
                         
                            self.loadingUpload2 = false;
                            self.dialogImport2 = false;
                            self.getUserProfile();
                            localStorage.setItem('credit', response.data.new_credit)
                        // location.reload();
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

        },
        onFileChangedLetter(e) {
       let selectedFile = e.target.files[0];
       this.docupost.file =  selectedFile;
    },
        addCredit(){
          let id = this.$route.params.id;
           var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
          if (r == true) {
            this.loadingAssign = true;
            axios
              .post("/api/add-credit-admin/"+id,{
                credit : this.new_credit,
                flag : this.creditFlag,
                client_flag : true
              },this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(this.creditFlag == 'remove'){
                  Vue.$toast.success('Credit removed successfully', {
                      position: 'top-right'
                      });
                }else{
                  Vue.$toast.success('Credit added successfully', {
                      position: 'top-right'
                      });
                }
                      this.creditDialog = false;
                      this.new_credit = 0;
                      this.loadingAssign = false;
                    this.getUserProfile();
                    this.getUserCredit()
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                      this.loadingAssign = false;
                  }

              })
            .catch(err => {
              Vue.$toast.error(err.response.data.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
            })
          }
        },
        getContracts(){
       axios({
          method: "GET",
          url: "/api/get-contract-template/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.value;
            if(data){
              this.contract = data;
            }
            })
    },
      getUserProfile() {
      let flag = 1;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let wasbi_url = process.env.VUE_APP_WASBI_URL;
          let id  = this.$route.params.id;
          let avatar_url = process.env.VUE_APP_WASBI_URL
        axios
          .get("/api/get-client/" + id,header)
          .then(response => {
              let data = response.data.data;
            if (flag == 1) {
              this.client = data;
              localStorage.setItem('company_logo',wasbi_url+data.logo)
              localStorage.setItem('company_id',data.company_id)
              this.tasks = response.data.task;
              this.credit_available = response.data.data.credit
              this.credit_used = response.data.credit_used
              if(response.data.contracts.length != 0){
                response.data.contracts.forEach(val => {
                  this.userContracts.push(val.contract_id);
                })
              }
              if(this.client.smart_credit_password)
                this.password = atob(this.client.smart_credit_password);
              if(this.client.smart_credit_user_name)
                this.username = this.client.smart_credit_user_name;
              this.reports = response.data.reports;
              this.docs = response.data.doc;
              console.log('sssdddd',this.docs);
              this.doc_count = response.data.doc.length;
              this.certifiedLetters = response.data.letters;
              this.grant_count = response.data.grant_count;
              
              this.avatar = response.data.data.avatar ? avatar_url+response.data.data.avatar : ''
              if(data.is_grant)
                this.grant = JSON.parse(data.grant_details);
              console.log('asdasd',this.grant);

              if(this.reports.length != 0){
                if(this.reports[0].import_from =='source')
                  this.assignDataFromSource(this.reports[0]);
                else
                  this.assignData(this.reports[0]);
              }
               if(response.data.doc.length != 0){
                response.data.doc.forEach(element => {
                  if(element.file_name.includes("ID")){
                    this.doc_id_flag = true;
                    this.doc_id_value = element.file_name
                  }
                  else if(element.file_name.includes("Bill")){
                    this.doc_bill_flag = true;
                    this.doc_bill_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_A")){
                    this.doc_letter_a_flag = true;
                    this.doc_letter_a_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_B")){
                    this.doc_letter_b_flag = true;
                    this.doc_letter_b_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_C")){
                    this.doc_letter_c_flag = true;
                    this.doc_letter_c_value = element.file_name
                  }else{
                    if(this.moreDocuments.length != 0){
                      let item_exist = false;
                      let index = 0;
                      this.moreDocuments.forEach((val,key) => {
                        if(val.id == element.file_name.match(/\d+/)[0]){
                          item_exist = true;
                          index = key;
                        }
                      })
                      if(item_exist){
                          this.moreDocuments[index].file_content = element.file_name;
                        }else{
                           this.moreDocuments.push({
                            id : element.file_name.match(/\d+/)[0],
                            file_name : element.file_name,
                            file_content : element.file_name,
                            file_full_name : element.document_name
                          })
                        }
                    }else{
                      this.moreDocuments.push({
                        id : element.file_name.match(/\d+/)[0],
                        file_name : element.file_name,
                        file_content : element.file_name,
                        file_full_name : element.document_name
                      })
                    }
                  }

                });
                
              }
              this.client.progress = this.client.progress +1;
              let process = this.client.progress;
              if( Math.round((100 * process) / 8) > 79)
                this.trade_flag = true;
                let flag =1;
                let done_flag = 1;
                this.testStates.forEach((val,key) => {
                  if(flag == (process-1)){
                    this.testStates[key].current = true;
                    done_flag = 0;
                    this.currentState = this.testStates[key].name;
                  }
                  flag = flag +1;
                  if(done_flag == 1)
                    this.testStates[key].done = true;
                })

            
          let count = this.settings.grant_count;
          
          for (let index = 1; index <= parseInt(count); index++) {
            if(this.grant_count == 0){
              this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
            }else{
              if(this.grant_count == index)
                this.grantStates.push( { name: 'Grant '+index,current: true,done : false });
              else if(this.grant_count > index)
                this.grantStates.push( { name: 'Grant '+index,current: false,done : true });
              else
                this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
            }
            
          }

              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              // Vue.$toast.error(err, {
              // position: 'top-right'
              // });

            });

      },
      deleteGrant(item,flag=''){
        if(confirm('Are you sure, You want to delete the grant?')){
          if(flag =='all'){
             axios({
            method: "POST",
            url: "/api/delete-grant-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectGrant}
            })
            .then(response => {
              this.getUsers();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }else{
            axios({
            method: "POST",
            url: "/api/delete-grant",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : item.id}
            })
            .then(response => {
              this.getUsers();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }
          }
      },
      deleteItemToBeCompleted(item='com'){
        if(confirm('Are you sure, You want to delete the grant?')){
          if(item =='com'){
             axios({
            method: "POST",
            url: "/api/delete-grant-user-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectedItemsCompleted}
            })
            .then(response => {
              this.getComGrants();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }else{
            axios({
            method: "POST",
            url: "/api/delete-grant-user-all",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id, id : this.selectedItemsToBeCompleted}
            })
            .then(response => {
              this.getInGrants();
              Vue.$toast.success("Grant has been removed", {
              position: 'top-right'
              });
            });
          }
        }
      },
      removeDocuments(){
          if(confirm('Are you sure, You want to remove all documents?')){
            this.loadingImport = true;
            axios({
            method: "POST",
            url: "/api/remove-all-documents",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: this.$route.params.id}
            })
            .then(response => {
            if(response.data.flag == 1){
              this.loadingImport = false;
              this.doc_id_flag = false;
              this.doc_id_value = ''
              this.doc_bill_flag = false;
              this.doc_bill_value = ''
              this.doc_letter_a_flag = false;
              this.doc_letter_a_value = ''
              this.moreDocuments = [];
              this.getUserProfile();
              Vue.$toast.success("All documents has been removed", {
              position: 'top-right'
              });
            }
            });
          }
        },
      uploadMultiple(){
        let id = 4;
        let self = this;
        
        if(!this.newFileName){
           Vue.$toast.error("File name is required", {
              position: 'top-right'
              });
        }else{
        if(this.moreDocuments.length != 0){
          this.moreDocuments.forEach(val => {
            id = val.id;
          })
          id = parseInt(id)+1;
        }
        self.loadingCustomeFile = true;
        const data = new FormData();
        let invalid_flag = false;
         let k = id;
          this.newFilesUpload.forEach(val => {
            if(!val.name.includes(self.client.email)){
              invalid_flag = true;
            }
            data.append('file_letter_'+k,val);
            k = k+1;
          })
          // if(invalid_flag == true){
          //   Vue.$toast.error("Document should contain user email", {
          //           position: 'top-right'
          //           });
          //   self.loadingCustomeFile = false;
          // }else{
          data.append('last_id',id);
          data.append('total_file',this.newFilesUpload.length);
          data.append('custom_file_name',this.newFileName);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.$route.params.id);
          this.loadingUpload = true;
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          self.loadingCustomeFile = false;
          self.newFileName = '';
          self.newFilesUpload = [];
          self.addFileFlag = false;
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          this.loadingUpload = false;
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
          // }
        }
      },
      getUsers(){
        this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_grants.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants.sortBy,
              sortDesc : this.datatable_options_grants.sortDesc && this.datatable_options_grants.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrants = data.data;
                this.pageCount = data.total_page
                this.totalCountAllGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      getInGrants(){
        this.loading = true;
            this.offset = (this.page_in - 1) * this.datatable_options_grants_in.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_in.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_in.sortBy,
              sortDesc : this.datatable_options_grants_in.sortDesc && this.datatable_options_grants_in.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search2,
              user_id : this.$route.params.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-submitted-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrantsIn = data.data;
                this.pageCountIn = data.total_page
                this.totalCountInprogressGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      getLetters(){
      this.loading = true;
      let self = this;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : 50,
              offset : offset,
              sort : '',
              sortDesc : 'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-client-letters/"+this.id,
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;
            if (response.data) {
               let letters = data.data;
               let arr = []
               arr = letters.reduce(function (acc, obj) {
                  var key = obj['round'];
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(obj);
                  
                  return acc;
                }, {}); 
                if(letters.length != 0)
                  self.last_letter = letters[0].round;
              //   letters.forEach(element => {
              //     arr[ele]
              //   });
               this.letters = arr




                this.pageCountLetter = data.total_page
                this.countLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
      getComGrants(){
        this.loading = true;
            this.offset = (this.page_com - 1) * this.datatable_options_grants_com.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_com.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_com.sortBy,
              sortDesc : this.datatable_options_grants_com.sortDesc && this.datatable_options_grants_com.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search3,
              user_id : this.$route.params.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-completed-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrantsCom = data.data;
                this.pageCountCom = data.total_page
                this.totalCountCompletedGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      assignData(data){
        let self = this;
        data = JSON.parse(data.report)
        // Score
        self.FinalData.profile.score.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
        self.FinalData.profile.score.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
        self.FinalData.profile.score.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
        // report date
        self.FinalData.profile.report_date.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].Source.InquiryDate
        self.FinalData.profile.report_date.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].Source.InquiryDate
        self.FinalData.profile.report_date.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].Source.InquiryDate
        // name and known as
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.BorrowerName.forEach(element => {
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
        });
        //Birth
        self.FinalData.profile.birth.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[0].date
        self.FinalData.profile.birth.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[1].date
        self.FinalData.profile.birth.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[2].date
        
        self.FinalData.credit_summary.transunion = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.TransUnion, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.TransUnion,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.TransUnion };
        self.FinalData.credit_summary.experian = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Experian, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Experian,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Experian}
        self.FinalData.credit_summary.equifax = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Equifax}
        //Inquiries
        if(!Array.isArray(data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition)){
          data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition =[data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition]
        }
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition.forEach(element =>{
          if(element.Inquiry.Source.Bureau.abbreviation == 'TransUnion'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : true, experian : false,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Experian'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : true,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Equifax'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : false,equifax : true})
          } 
        })
      this.chartOptionseq = {
           
              debug: false, 
              type: 'gauge ', 
              responsive : false,
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.equifax)]] 
                } 
              ] 

        
         }
         this.chartOptionsex = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.experian)]] 
                } 
              ] 

        
         }
         this.chartOptionstu = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.transunion)]] 
                } 
              ] 

        
         }
      
      },
      assignDataFromSource(data){
        let self = this;
        data = JSON.parse(data.report)
        // Score
        self.FinalData.profile.score.transunion = data[3][1][1][1]
        self.FinalData.profile.score.experian = data[3][1][1][2]
        self.FinalData.profile.score.equifax = data[3][1][1][3]
        // report date
        self.FinalData.profile.report_date.transunion = data[2][1][1][1].replace('-', '')
        self.FinalData.profile.report_date.experian = data[2][1][1][2].replace('-', '')
        self.FinalData.profile.report_date.equifax = data[2][1][1][3].replace('-', '')
        // name and known as
        self.FinalData.profile.name.transunion = data[2][1][2][1].replace('-', '')
         self.FinalData.profile.name.experian = data[2][1][2][2].replace('-', '')
         self.FinalData.profile.name.equifax = data[2][1][2][3].replace('-', '')
         self.FinalData.profile.known_as.transunion = data[2][1][3][1]
         self.FinalData.profile.known_as.experian = data[2][1][3][2]
         self.FinalData.profile.known_as.equifax = data[2][1][3][3]
         //Birth
        self.FinalData.profile.birth.transunion = data[2][1][5][1].replace('-', '')
        self.FinalData.profile.birth.experian = data[2][1][5][2].replace('-', '')
        self.FinalData.profile.birth.equifax = data[2][1][5][3].replace('-', '')
        //Addresses
        self.FinalData.profile.current_address.transunion.push(data[2][1][6][1].replace('-', ''));
        self.FinalData.profile.current_address.experian.push(data[2][1][6][2].replace('-', ''));
        self.FinalData.profile.current_address.equifax.push(data[2][1][6][3].replace('-', ''));
        //Employer
        self.FinalData.profile.employer.transunion.push(data[2][1][8][1].replace('-', ''));
        self.FinalData.profile.employer.experian.push(data[2][1][8][2].replace('-', ''));
        self.FinalData.profile.employer.equifax.push(data[2][1][8][3].replace('-', ''));
        //Prev Addresses
        self.FinalData.profile.previous_address.transunion.push(data[2][1][7][1].replace('-', ''));
        self.FinalData.profile.previous_address.experian.push(data[2][1][7][2].replace('-', ''));
        self.FinalData.profile.previous_address.equifax.push(data[2][1][7][3].replace('-', ''));

        //Summary
        self.FinalData.credit_summary.transunion.TotalAccounts = data[4][1][1][1]
        self.FinalData.credit_summary.experian.TotalAccounts = data[4][1][1][2]
        self.FinalData.credit_summary.equifax.TotalAccounts = data[4][1][1][3]
        self.FinalData.credit_summary.transunion.OpenAccounts = data[4][1][2][1]
        self.FinalData.credit_summary.experian.OpenAccounts = data[4][1][2][2]
        self.FinalData.credit_summary.equifax.OpenAccounts = data[4][1][2][3]
        
        self.FinalData.credit_summary.transunion.CloseAccounts = data[4][1][3][1]
        self.FinalData.credit_summary.experian.CloseAccounts = data[4][1][3][2]
        self.FinalData.credit_summary.equifax.CloseAccounts = data[4][1][3][3]
        
        self.FinalData.credit_summary.transunion.DelinquentAccounts = data[4][1][4][1]
        self.FinalData.credit_summary.experian.DelinquentAccounts = data[4][1][4][2]
        self.FinalData.credit_summary.equifax.DelinquentAccounts = data[4][1][4][3]
        
        self.FinalData.credit_summary.transunion.DerogatoryAccounts = data[4][1][5][1]
        self.FinalData.credit_summary.experian.DerogatoryAccounts = data[4][1][5][2]
        self.FinalData.credit_summary.equifax.DerogatoryAccounts = data[4][1][5][3]
        self.FinalData.credit_summary.transunion.TotalBalances = Number(data[4][1][7][1].replace(/[^0-9.-]+/g,""))
        self.FinalData.credit_summary.experian.TotalBalances = Number(data[4][1][7][2].replace(/[^0-9.-]+/g,""))
        self.FinalData.credit_summary.equifax.TotalBalances = Number(data[4][1][7][3].replace(/[^0-9.-]+/g,""))
        
        self.FinalData.credit_summary.transunion.TotalMonthlyPayments = Number(data[4][1][8][1].replace(/[^0-9.-]+/g,""))
        self.FinalData.credit_summary.experian.TotalMonthlyPayments = Number(data[4][1][8][2].replace(/[^0-9.-]+/g,""))
        self.FinalData.credit_summary.equifax.TotalMonthlyPayments = Number(data[4][1][8][3].replace(/[^0-9.-]+/g,""))
        
        self.FinalData.credit_summary.transunion.NumberOfRecords = data[4][1][9][1]
        self.FinalData.credit_summary.experian.NumberOfRecords = data[4][1][9][2]
        self.FinalData.credit_summary.equifax.NumberOfRecords = data[4][1][9][3]
        
        self.FinalData.credit_summary.transunion.Collection = data[4][1][6][1]
        self.FinalData.credit_summary.experian.Collection = data[4][1][6][2]
        self.FinalData.credit_summary.equifax.Collection = data[4][1][6][3]
        
        self.FinalData.credit_summary.transunion.NumberInLast2Years = data[4][1][10][1]
        self.FinalData.credit_summary.experian.NumberInLast2Years = data[4][1][10][2]
        self.FinalData.credit_summary.equifax.NumberInLast2Years = data[4][1][10][3]
        // //Inquiries
        // for (let index = 1; index < data[6][1].length; index++) {
        //   const element = data[6][1][index];
        //   if(element[3] == 'TransUnion')
        //     self.FinalData.credit_inquiries.push({title: element[0],date: element[2],transunion : true, experian : false,equifax : false})
        //   if(element[3] == 'Experian')
        //     self.FinalData.credit_inquiries.push({title: element[0],date: element[2],transunion : false, experian : true,equifax : false})
        //   if(element[3] == 'Equifax')
        //     self.FinalData.credit_inquiries.push({title: element[0],date: element[2],transunion : false, experian : false,equifax : true})
        // }
      this.chartOptionseq = {
           
              debug: false, 
              type: 'gauge ', 
              responsive : false,
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.equifax)]] 
                } 
              ] 

        
         }
         this.chartOptionsex = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.experian)]] 
                } 
              ] 

        
         }
         this.chartOptionstu = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.transunion)]] 
                } 
              ] 

        
         }
      
      },
        
        

    
    },
     mounted() {
      let self = this;
      if(!localStorage.getItem('token'))
            this.$router.push('/login')
        // this.getUsers();
        this.getUserProfile();
        this.getContracts();
        this.getLetters()
        this.getLocation();
        this.getFocusArea();
        this.getCompanySettings();
    
  },
  watch: {
            datatable_options_letter : {
              handler () {
                          this.getLetters()
                          },
                          deep: true,
            },
            datatable_options_grants: {
                handler () {
                    console.log(this.datatable_options_grants);
                this.getUsers()
                },
                deep: true,
            },
            datatable_options_grants_in: {
                handler () {
                    console.log(this.datatable_options_grants_in);
                this.getInGrants()
                },
                deep: true,
            },
            datatable_options_grants_com: {
                handler () {
                    console.log(this.datatable_options_grants_com);
                this.getComGrants()
                },
                deep: true,
            },
        },
}
</script>

<style>
.v-tabs-slider-wrapper{
  height: 5px!important;
}
.card-border{
      color: white;
    -webkit-transform: rotate(-90deg);
    position: absolute;
    justify-content: center;
    text-align: center;
    display: block;
    height: 100%;
    margin-left: 175px; 
}
.checkbox-doc .v-messages{
  display : none;
} 
.only-next{
  display:none!important;
}
.white-text{
  text-align: center;
  color :#FFFFFF;
}
.customer-overview-score-2 {
  margin-bottom: 0px;
  font-size: 70px;
  line-height: 60px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: -4px;
}
.columnChart {
    height: 400px;
}
.text-black{
   text-align: center;
}
.table-one tbody tr:nth-of-type(even) {
   background-color: #EAF1F5;
 }
 .client_card_info_address{
  margin-bottom: 12px;
color: #272727;
font-weight: 700;
 }
 .customer-side-panel-ssn-left{
    display: inline-block;
    font-size: 13px;
    color: #272727;
    font-weight: 700;

 }
 #brandingLogo {
  display:none;
}
</style>